import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import { useFetch, QUERY_KEYS } from "api";
import { queryClient } from "api/queryClient";
import { INDIVIDUAL_SOURCES, ENTITY_SOURCES, TRUST_SOURCES } from "utils/constants";

import DocumentsForm from "./component";
import { BROKER, CLIENT, SPV, SPV_CLASS, SPV_INVESTMENT, CONNECTION } from "./constants";

const ClientDocumentsForm = props => {
  const { id: sourceId } = useParams();
  const { data: source } = useFetch(QUERY_KEYS.CLIENT_PROFILES.detail(sourceId));
  const isClient = [INDIVIDUAL_SOURCES, ENTITY_SOURCES, TRUST_SOURCES].includes(source?.sourceType);
  return (
    <DocumentsForm
      {...props}
      queryBase={QUERY_KEYS.CLIENT_DOCUMENTS}
      queryParams={[sourceId]}
      type="Client"
      documentTypeOptions={isClient ? CLIENT : BROKER}
    />
  );
};

ClientDocumentsForm.propTypes = {
  isClient: PropTypes.bool.isRequired,
};

const ExpiringDocumentsForm = props => (
  <DocumentsForm
    {...props}
    queryBase={QUERY_KEYS.EXPIRING_DOCUMENTS}
    queryParams={[]}
    type="Client"
    documentTypeOptions={BROKER}
  />
);

ExpiringDocumentsForm.propTypes = {
  existingDocument: PropTypes.shape({
    type: PropTypes.string,
  }).isRequired,
};

const SPVDocumentsForm = props => {
  const { id: spvId } = useParams();
  return (
    <DocumentsForm
      {...props}
      queryBase={QUERY_KEYS.SPV_DOCUMENTS}
      queryParams={[spvId]}
      type="SPV"
      documentTypeOptions={SPV}
    />
  );
};

const SPVClassDocumentsForm = props => {
  const { id: spvId } = useParams();
  return (
    <DocumentsForm
      {...props}
      queryBase={QUERY_KEYS.SPV_CLASS_DOCUMENTS}
      queryParams={[spvId]}
      type="SPV Class"
      documentTypeOptions={SPV_CLASS}
    />
  );
};

const SPVInvestmentDocumentsForm = props => {
  const { id: spvInvestmentId } = useParams();
  return <SPVInvestmentDocumentsFormWithId spvInvestmentId={spvInvestmentId} {...props} />;
};

const SPVInvestmentDocumentsFormWithId = ({ spvInvestmentId, ...props }) => (
  <DocumentsForm
    {...props}
    queryBase={QUERY_KEYS.SPV_INVESTMENT_DOCUMENTS}
    queryParams={[spvInvestmentId]}
    type="SPV Investment"
    documentTypeOptions={SPV_INVESTMENT}
  />
);

SPVInvestmentDocumentsFormWithId.propTypes = {
  spvInvestmentId: PropTypes.number.isRequired,
};

const ConnectionDocumentsForm = props => {
  const { id: tradeId } = useParams();

  const closeModal = () => {
    props.closeModal();
    queryClient.invalidateQueries(QUERY_KEYS.CONNECTIONS.detail(tradeId));
  };
  return (
    <DocumentsForm
      {...props}
      closeModal={closeModal}
      queryBase={props.queryKeys}
      queryParams={[tradeId]}
      type="Connection"
      documentTypeOptions={CONNECTION}
    />
  );
};

ConnectionDocumentsForm.propTypes = {
  queryKeys: PropTypes.shape({
    detail: PropTypes.func,
  }).isRequired,
  closeModal: PropTypes.func.isRequired,
};

export {
  ClientDocumentsForm,
  ExpiringDocumentsForm,
  SPVDocumentsForm,
  SPVClassDocumentsForm,
  SPVInvestmentDocumentsForm,
  SPVInvestmentDocumentsFormWithId,
  ConnectionDocumentsForm,
};

import { queryClient } from "./queryClient";
import { updateData, formatAsJsonApi } from "./writeInternals";
import { cleanJsonApiData } from "./fetchInternals";
import { LIST, DETAIL } from "./constants";
import { ACTIONS } from "utils/StateProvider";

const defaultErrorMessage = "Validation failed, please check your information and try again";

/**
 * A simplified alternative to useWrite when hooks are unavailable.
 *
 * @param {Array} queryKey
 * @param {Object} data
 * @param {Object} config
 * @returns {Promise}
 */
const writeQuery = async (queryKey, data, dispatch, config = {}) => {
  const jsonApiData = formatAsJsonApi(data);

  try {
    const response = await queryClient.executeMutation({
      mutationKey: queryKey,
      mutationFn: () => updateData({ key: queryKey, data: jsonApiData, config }),
    });

    queryClient.invalidateQueries([queryKey[0], LIST]);
    queryClient.invalidateQueries(queryKey.slice(0, 3));
    if (queryKey.length >= 2 && queryKey[1] === DETAIL) {
      queryClient.setQueryData(queryKey.slice(0, 3), response);
      queryClient.setQueryData(queryKey, response);
    }

    const { data: cleanedData, relationships } = cleanJsonApiData(response);

    if (!config?.silent) {
      if (config?.message) {
        dispatch({ type: ACTIONS.addToast, message: config.message });
      } else {
        dispatch({ type: ACTIONS.addToast, message: "Successfully updated" });
      }
    }

    return { data: cleanedData, relationships };
  } catch (e) {
    let err = e?.response?.data?.errors?.[0];

    if (err && err?.source?.pointer.startsWith("/data")) {
      err = err.detail;
    }

    dispatch({
      type: ACTIONS.addToast,
      message: err || defaultErrorMessage,
    });

    return Promise.reject(err || defaultErrorMessage);
  }
};

export default writeQuery;

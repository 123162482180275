import React from "react";
import styled from "styled-components";

import { StyledHeadline2 } from "yuka";

import { Checkbox, InputRow, RadioGroup } from "forms/FormComponents";
import { FEATURE_FLAG_YAC_ONBOARDING } from "utils/featureFlags";
import { requiredBoolean } from "utils/form/inputValidations";

import {
  BUYER_AGREEMENT,
  SELLER_AGREEMENT,
  BUYER_FIRM_ORDER,
  SELLER_FIRM_ORDER,
} from "./constants";

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledHeadline = styled(StyledHeadline2)`
  margin-bottom: 12px;
`;

const engagementOptions = [
  { label: "Buyer Agreement", value: BUYER_AGREEMENT },
  { label: "Buyer Firm Order", value: BUYER_FIRM_ORDER },
  { label: "Seller Agreement", value: SELLER_AGREEMENT },
  { label: "Sell Firm Order", value: SELLER_FIRM_ORDER },
  { label: "N/A", value: "" },
];

const documentStackOptions = [
  { label: "Use Zanbato Securities document stack", value: false },
  { label: "Use Young America Capital (YAC) document stack", value: true },
];

const Documents = () => (
  <StyledContainer>
    <StyledHeadline>Order Forms</StyledHeadline>
    <InputRow>
      <RadioGroup items={engagementOptions} name="engagementType" />
    </InputRow>
    <StyledHeadline>Know Your Customer (KYC)</StyledHeadline>
    <InputRow>
      <Checkbox
        name="needsKyc"
        label="Request client to complete KYC"
        helpText="Leave unchecked if the client has already completed KYC or if the client will complete KYC at a later time."
      />
    </InputRow>
    <StyledHeadline>Document Stack</StyledHeadline>
    {FEATURE_FLAG_YAC_ONBOARDING() && (
      <InputRow>
        <RadioGroup
          items={documentStackOptions}
          name="isUsingYacDocuments"
          validate={[requiredBoolean]}
        />
      </InputRow>
    )}
  </StyledContainer>
);

export default Documents;

const ParticipantNameColumn = headerText => ({
  id: "participantName",
  accessor: "participantName",
  width: 33,
  header: headerText,
  sortable: false,
});

const ProfileTypeColumn = {
  id: "profileType",
  accessor: "profileType",
  width: 33,
  header: "Profile Type",
  sortable: false,
};

const DocumentsColumn = {
  id: "documentCount",
  accessor: "documentCount",
  width: 33,
  header: "Documents",
  sortable: false,
};

const headerTableColumns = participantType => [
  ParticipantNameColumn(participantType),
  ProfileTypeColumn,
  DocumentsColumn,
];

export { headerTableColumns };

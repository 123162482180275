import styled from "styled-components";
import PropTypes from "prop-types";

import { ConnectionComplianceStatusCard } from "utils/connections";
import {
  DEAL_FILE_STATUS_NOT_READY,
  DEAL_FILE_STATUS_APPROVED,
  DEAL_FILE_STATUS_INFORMATION_REQUESTED,
} from "utils/constants";
import DealFile from "templates/DealFile";

import DropboxCard from "./DropboxCard";
import LinkParticipantDocumentsCard from "./LinkParticipantDocumentsCard";

const StyledDealFileTab = styled.div`
  padding: 16px 0;
  align-items: flex-start;
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 16px;
  min-height: 0;
  overflow-y: auto;
`;

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-width: 0;
`;

const DealFileTab = ({ connection }) => (
  <StyledDealFileTab>
    <StyledColumn>
      {connection.dealFileStatus !== DEAL_FILE_STATUS_NOT_READY &&
        connection.dealFileStatus !== DEAL_FILE_STATUS_APPROVED && (
          <ConnectionComplianceStatusCard
            connection={connection}
            includeRerequest={connection.dealFileStatus === DEAL_FILE_STATUS_INFORMATION_REQUESTED}
          />
        )}
      <DealFile connection={connection} />
    </StyledColumn>
    <StyledColumn>
      <DropboxCard connection={connection} />
      <LinkParticipantDocumentsCard connection={connection} />
    </StyledColumn>
  </StyledDealFileTab>
);

DealFileTab.propTypes = {
  connection: PropTypes.shape({
    buysideDocuments: PropTypes.arrayOf(PropTypes.array).isRequired,
    sellsideDocuments: PropTypes.arrayOf(PropTypes.array).isRequired,
    transferDocuments: PropTypes.arrayOf(PropTypes.array).isRequired,
    tradeId: PropTypes.string.isRequired,
    dealFileStatus: PropTypes.string,
  }).isRequired,
};

export default DealFileTab;

import {
  SUBSCRIBER_SOURCES,
  TRADING_GROUP_SOURCES,
  NON_SUBSCRIBER_SOURCES,
  INDIVIDUAL_SOURCES,
  ENTITY_SOURCES,
  TRUST_SOURCES,
  KYC_APPROVED,
  KYC_EXPIRED,
  KYC_INCOMPLETE,
  KYC_REVIEW,
  KYC_INFORMATION_REQUESTED,
  KYC_INFORMATION_UPDATED,
} from "utils/constants";

const ALL_TAB = "All";
const BROKERS_TAB = "Broker Dealers";
const CLIENTS_TAB = "Buyers & Sellers";

const TABS = [{ label: ALL_TAB }, { label: BROKERS_TAB }, { label: CLIENTS_TAB }];

const TYPE_OPTIONS = {
  [ALL_TAB]: [
    SUBSCRIBER_SOURCES,
    TRADING_GROUP_SOURCES,
    NON_SUBSCRIBER_SOURCES,
    INDIVIDUAL_SOURCES,
    ENTITY_SOURCES,
    TRUST_SOURCES,
  ],
  [BROKERS_TAB]: [SUBSCRIBER_SOURCES, TRADING_GROUP_SOURCES, NON_SUBSCRIBER_SOURCES],
  [CLIENTS_TAB]: [INDIVIDUAL_SOURCES, ENTITY_SOURCES, TRUST_SOURCES],
};

const KYC_OPTIONS = [
  KYC_APPROVED,
  KYC_EXPIRED,
  KYC_INCOMPLETE,
  KYC_REVIEW,
  KYC_INFORMATION_REQUESTED,
  KYC_INFORMATION_UPDATED,
];

export { KYC_OPTIONS, TABS, TYPE_OPTIONS, ALL_TAB, BROKERS_TAB, CLIENTS_TAB };

import React from "react";
import PropTypes from "prop-types";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";

import {
  HyperLink,
  overline,
  FinalFormField,
  FontColors,
  StyledBody1,
  StyledBody2,
  StyledOverline,
} from "yuka";

import { getRelatedId } from "api";
import { InputRow } from "forms/FormComponents";
import { useFormValue } from "utils/hooks/form";
import { expandedMoneyFormat } from "utils/displayFormatUtils";
import SecurityField from "forms/fields/SecurityField";
import { required } from "utils/form/inputValidations";
import { isSpvTrade } from "utils/connections";

import ConnectionSPVFieldSet from "./ConnectionSPVFieldSet";
import ConnectionValueFieldSet from "./ConnectionValueFieldSet";
import { QUERY_PARAM } from "./constants";

const StyledTypeDisplay = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1;
  min-width: 0;
`;

const StyledHyperLink = styled(HyperLink)`
  margin-left: 8px;
`;

const StyledTotalValue = styled(StyledBody1)`
  margin-left: 8px;
`;

const StyledConnectionType = styled.span`
  ${overline}
  ${FontColors.theme50}
  margin-bottom: 8px;
`;

const NegotiatedTermsFieldSet = props => {
  const totalValue = Number(useFormValue("totalValue", 0));
  const [, setSearchParams] = useSearchParams();
  const openTypeForm = () => {
    setSearchParams({ [QUERY_PARAM]: "Type" });
  };
  return (
    <>
      <InputRow>
        <StyledTypeDisplay>
          <StyledConnectionType>Connection Type</StyledConnectionType>
          <div>
            <StyledBody2>{props.connection.tradeType}</StyledBody2>
            <StyledHyperLink onClick={openTypeForm}>(Edit)</StyledHyperLink>
          </div>
        </StyledTypeDisplay>
      </InputRow>
      {isSpvTrade(props.connection) ? <ConnectionSPVFieldSet /> : <ConnectionValueFieldSet />}
      <InputRow>
        <FinalFormField
          type="custom-block"
          component={SecurityField}
          name="security"
          validate={[required]}
          companyId={getRelatedId(props.connection.company)}
        />
      </InputRow>
      <StyledOverline>Total Value:</StyledOverline>
      <StyledTotalValue>{expandedMoneyFormat(totalValue)}</StyledTotalValue>
    </>
  );
};

NegotiatedTermsFieldSet.propTypes = {
  connection: PropTypes.shape({
    tradeType: PropTypes.string.isRequired,
    company: PropTypes.array,
  }).isRequired,
};

export default NegotiatedTermsFieldSet;

import { useContext } from "react";
import { getRelatedObj } from "api";
import RelationshipsContext from "utils/RelationshipsContext";
import { REMINDER_RECIPIENT_TEAM } from "utils/constants";

const RepCell = ({ value: task }) => {
  const relationships = useContext(RelationshipsContext);
  const repObj = getRelatedObj(task.sendReminderTo, relationships);

  if (task.reminderRecipients === REMINDER_RECIPIENT_TEAM) {
    return "Execution Team";
  }
  return repObj?.name;
};

export default RepCell;

import { useMemo, useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { NumberCell, PercentageCell } from "yuka";

import { GlobalContext, ACTIONS } from "utils/StateProvider";
import { shortMoneyFormat } from "utils/displayFormatUtils";
import { useMpSource, mpTrack } from "utils/mixpanel";
import useTableSort from "utils/tables/useTableSort";

import SPVNameCell from "./SPVNameCell";

const SPV_TABLE_NAME = "SPV_PROFILES";
const SPV_CLASS_TABLE_NAME = "SPV_CLASSES";

const SPV_TABLE_INITIAL_SORT_STATE = { id: "createdOn", desc: true };
const SPV_CLASS_TABLE_INITIAL_SORT_STATE = { id: "name", desc: false };

const useSPVTableConfig = () => {
  const {
    state: { tableSort },
    dispatch,
  } = useContext(GlobalContext);

  const navigate = useNavigate();
  const mpSource = useMpSource();

  const onRowClick = useCallback(
    ({ row: { original: originalData } }) => {
      mpTrack("click into spv profile", {
        mpSource,
        spv: originalData.name,
        type: originalData.spvType,
      });
      if (originalData.apiId) {
        navigate(`${originalData.apiId}/`);
      }
    },
    [navigate, mpSource]
  );

  const onSort = useTableSort(ACTIONS, dispatch, SPV_TABLE_NAME);
  const sortState = useMemo(() => {
    return tableSort[SPV_TABLE_NAME] || [{ ...SPV_TABLE_INITIAL_SORT_STATE, hideArrow: true }];
  }, [tableSort]);

  const sortQueryParam = useMemo(() => {
    if (sortState?.length) {
      return sortState[0].desc ? `-${sortState[0].id}` : sortState[0].id;
    }
    return undefined;
  }, [sortState]);

  return {
    onRowClick,
    sortQueryParam,
    sortState,
    onSort,
  };
};

const useSPVClassTableConfig = () => {
  const {
    state: { tableSort },
    dispatch,
  } = useContext(GlobalContext);

  const navigate = useNavigate();
  const mpSource = useMpSource();

  const onRowClick = useCallback(
    ({ row: { original: originalData } }) => {
      mpTrack("click into spv class profile", {
        mpSource,
        spv: originalData.name,
        type: originalData.spvType,
      });
      if (originalData.apiId) {
        navigate(`class/${originalData.apiId}/`);
      }
    },
    [navigate, mpSource]
  );

  const onSort = useTableSort(ACTIONS, dispatch, SPV_CLASS_TABLE_NAME);
  const sortState = useMemo(() => {
    return (
      tableSort[SPV_CLASS_TABLE_NAME] || [
        { ...SPV_CLASS_TABLE_INITIAL_SORT_STATE, hideArrow: true },
      ]
    );
  }, [tableSort]);

  return {
    sortState,
    onRowClick,
    onSort,
  };
};

// SPV Table columns
const SPVNameColumn = {
  id: "name",
  accessor: spv => spv,
  width: 33,
  header: "Name",
  sortable: true,
  cellRenderer: SPVNameCell,
};

const SPVTypeColumn = {
  id: "spvType",
  accessor: "spvType",
  width: 33,
  header: "Type",
  sortable: true,
};

const CompanyColumn = {
  id: "companyName",
  accessor: "companyName",
  width: 33,
  header: "Portfolio Company",
  sortable: true,
};

// SPV Classes Table columns
const NameColumn = {
  id: "name",
  accessor: "name",
  width: 25,
  header: "Name",
  sortable: true,
};

const SeriesColumn = {
  id: "spvName",
  accessor: "spvName",
  width: 33,
  header: "SPV",
  sortable: true,
};

const SPVColumn = {
  id: "masterName",
  accessor: "masterName",
  width: 33,
  header: "Master SPV",
  sortable: true,
};

const SecurityColumn = {
  id: "securityType",
  accessor: "securityType",
  width: 33,
  header: "Security",
  sortable: true,
};

const PricePerShareColumn = {
  id: "pricePerShare",
  accessor: "pricePerShare",
  width: 25,
  header: "Price Per Share",
  sortable: true,
  formatter: value => shortMoneyFormat(value, 2),
  cellRenderer: NumberCell,
};

const PlacementFeeColumn = {
  id: "placementFee",
  accessor: "placementFee",
  width: 25,
  header: "Placement Fee",
  sortable: true,
  cellRenderer: PercentageCell,
};

const ManagementFeeColumn = {
  id: "managementFee",
  accessor: "managementFee",
  width: 25,
  header: "Management Fee",
  sortable: true,
  cellRenderer: PercentageCell,
};

const CarriedInterestedColumn = {
  id: "carriedInterest",
  accessor: "carriedInterest",
  width: 25,
  header: "Carried Interest",
  sortable: true,
  cellRenderer: PercentageCell,
};

const spvTableColumns = [SPVNameColumn, SPVTypeColumn, CompanyColumn];
const spvClassesTableColumns = [
  NameColumn,
  SeriesColumn,
  SPVColumn,
  SecurityColumn,
  PricePerShareColumn,
  PlacementFeeColumn,
  ManagementFeeColumn,
  CarriedInterestedColumn,
];

export { spvTableColumns, spvClassesTableColumns, useSPVTableConfig, useSPVClassTableConfig };

import React, { useState } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import styled from "styled-components";
import { DateTime } from "luxon";

import { Thumbnail, HyperLink, Fonts, TabList, useCurrentTab, LinkTypes, LinkStyles } from "yuka";

import { QUERY_KEYS, getRelatedObj, useFetch } from "api";
import DisconnectForm from "forms/DisconnectForm";
import { MixpanelSourceContext, mpTrack } from "utils/mixpanel";
import { ZXLink } from "utils/routing";
import { ConnectionPhaseBadge, DealFileStatusBadge } from "utils/connections";
import RelationshipsContext from "utils/RelationshipsContext";

import { FrontendError, Loading } from "templates/ErrorPages";
import { useDispatch, ACTIONS } from "utils/StateProvider";

import ConnectionActions from "./ConnectionActions";
import OverviewTab from "./OverviewTab";
import KYCChecklistTab from "./KYCChecklistTab";
import DealFileTab from "./DealFileTab";

const StyledHeader = styled.div`
  display: flex;
  margin-top: 16px;
`;

const StyledHeaderText = styled.div`
  min-width: fit-content;
  margin-right: 32px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;

  > * {
    align-items: center;
    display: flex;
    gap: 4px;
  }
`;

const StyledThumbnail = styled(Thumbnail)`
  margin-right: 12px;
`;

const OVERVIEW = "Overview";
const DEAL_FILE = "Deal File";
const KYC_CHECKLIST = "KYC Checklist";

const TAB_ARRAY = [{ label: OVERVIEW }, { label: DEAL_FILE }];

const ConnectionProfileView = ({ name }) => {
  const currentTab = useCurrentTab();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { isLoading, isLoadingError, data, relationships } = useFetch(
    QUERY_KEYS.CONNECTIONS.detail(id)
  );

  const [disconnectFormActive, setDisconnectFormActive] = useState(false);

  if (isLoading) {
    return <Loading />;
  }

  if (isLoadingError) {
    return <FrontendError />;
  }

  const connection = data;
  const company = getRelatedObj(connection.company, relationships);

  const mpTrackTabChange = tab => {
    mpTrack("toggle connection tab", { tab });
  };

  // Copy trade ID to clipboard
  const handleClickTradeId = () => {
    // https://developer.mozilla.org/en-US/docs/Mozilla/Add-ons/WebExtensions/Interact_with_the_clipboard
    navigator.permissions.query({ name: "clipboard-write" }).then(result => {
      if (result.state === "granted" || result.state === "prompt") {
        navigator.clipboard.writeText(connection.tradeId);
        dispatch({
          type: ACTIONS.addToast,
          message: `Copied "${connection.tradeId}" to clipboard`,
        });
      }
    });
  };

  return (
    <MixpanelSourceContext.Provider value="connection profile">
      <RelationshipsContext.Provider value={relationships}>
        <Fonts.Caption2theme50>
          <HyperLink url="../" linkType={LinkTypes.LOCAL_LINK}>
            {name} Connections
          </HyperLink>
          <span>
            {" "}
            / {company.name} ({connection.tradeId})
          </span>
        </Fonts.Caption2theme50>
        <StyledHeader>
          <StyledThumbnail size="48px" src={company.thumbnail} />
          <StyledHeaderText>
            <Fonts.Headline1theme100>
              <HyperLink onClick={handleClickTradeId} linkStyle={LinkStyles.INVISIBLE}>
                {connection.tradeId}
              </HyperLink>
              &nbsp;(
              <ZXLink
                component={HyperLink}
                linkStyle={LinkStyles.INVISIBLE}
                url={company.zxUrl}
                profileType="company"
              >
                {company.legalName || company.name}
              </ZXLink>
              )&nbsp;
            </Fonts.Headline1theme100>
            <Fonts.Caption1theme50>
              Connected on{" "}
              {DateTime.fromISO(connection.createdOn).toLocaleString(DateTime.DATE_SHORT)}
              {!connection.disconnectedOn && " | "}
              {!connection.disconnectedOn && (
                <HyperLink onClick={setDisconnectFormActive}>Disconnect</HyperLink>
              )}
            </Fonts.Caption1theme50>
            <span>
              <ConnectionPhaseBadge phase={connection.phase} />
              <DealFileStatusBadge connection={connection} />
            </span>
          </StyledHeaderText>
          <ConnectionActions connection={connection} />
        </StyledHeader>
        <TabList tabs={TAB_ARRAY} onTabChange={mpTrackTabChange} />
        {currentTab === OVERVIEW && <OverviewTab connection={connection} />}
        {currentTab === DEAL_FILE && <DealFileTab connection={connection} />}
        {currentTab === KYC_CHECKLIST && <KYCChecklistTab connection={connection} />}
        {disconnectFormActive && (
          <DisconnectForm connection={connection} onClose={() => setDisconnectFormActive(false)} />
        )}
      </RelationshipsContext.Provider>
    </MixpanelSourceContext.Provider>
  );
};

ConnectionProfileView.propTypes = {
  name: PropTypes.string.isRequired,
};

export default ConnectionProfileView;

import { debounce } from "lodash-es";
import { useMemo, useContext } from "react";

import { mpTrack } from "utils/mixpanel";
import { HALF_SECOND } from "utils/constants";
import { isEqual, findKey, pickBy } from "utils";
import { GlobalContext } from "utils/StateProvider";
import { DEAL_FILE_FILTERS } from "forms/filterNames";

import { CATEGORY_OPTIONS } from "../constants";

const fieldToEventTypeMap = {
  name: "File name",
  category: "Category",
};

const onChangeFilters = debounce(
  (values, prevValues) => {
    const changedValue = findKey(pickBy(values, (val, key) => prevValues[key] !== val));
    if (changedValue || !isEqual(values, prevValues)) {
      mpTrack("filter deal file table", {
        type: fieldToEventTypeMap[changedValue] || "Reset",
      });
    }
  },
  HALF_SECOND,
  { trailing: true }
);

const useDealFileFilterConfig = () => {
  const {
    state: { forms },
  } = useContext(GlobalContext);

  const formValues = pickBy(forms[DEAL_FILE_FILTERS] || {});
  const queryParams = useMemo(
    () => ({
      "filter[name]": formValues.name,
      "filter[category]": formValues.category,
      "filter[isArchived]": false,
    }),
    [formValues]
  );

  return {
    queryParams,
    filterProps: {
      initialValues: {
        category: CATEGORY_OPTIONS,
      },
    },
  };
};

export default useDealFileFilterConfig;
export { onChangeFilters };

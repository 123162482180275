import PropTypes from "prop-types";
import { useContext } from "react";
import { BadgeCell, BadgeStyles } from "yuka";

import RelationshipsContext from "utils/RelationshipsContext";
import { getRelatedObj } from "api";

const MANUAL_UPLOAD = "Manual Upload";
const COMPLETED = "Completed";
const DOCUSIGN_PENDING = "DocuSign Pending";

const BADGE_MAPPING = {
  [MANUAL_UPLOAD]: BadgeStyles.LIGHT_BLUE,
  [COMPLETED]: BadgeStyles.BUY_GREEN,
  [DOCUSIGN_PENDING]: BadgeStyles.YELLOW,
};

const InvestmentOnboardingRequestCell = ({ value, column, ...props }) => {
  const { spvInvestmentRelationships: relationships } = useContext(RelationshipsContext);
  const onboardingRequest = getRelatedObj(value, relationships);
  let statusValue;
  if (!onboardingRequest) {
    statusValue = MANUAL_UPLOAD;
  } else if (onboardingRequest.completedOn) {
    statusValue = COMPLETED;
  } else {
    statusValue = DOCUSIGN_PENDING;
  }
  return (
    <BadgeCell value={statusValue} {...props} column={{ ...column, badgeMapping: BADGE_MAPPING }} />
  );
};

InvestmentOnboardingRequestCell.propTypes = {
  value: PropTypes.array.isRequired,
  column: PropTypes.object.isRequired,
};

export default InvestmentOnboardingRequestCell;

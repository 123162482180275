import ReactDOM from "react-dom";
import { useState } from "react";
import { useAnchoredMenu, IconButton, DotsVerticalIcon, LinkTypes } from "yuka";
import styled from "styled-components";
import PropTypes from "prop-types";

import { fetchQuery, QUERY_KEYS, getRelatedId } from "api";
import { SPVInvestmentDocumentsFormWithId } from "forms/DocumentsForm";
import { mpTrack } from "utils/mixpanel";

const StyledCell = styled.div`
  display: flex;
`;

const DropdownCell = ({ value: spvInvestment }) => {
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const bulkDownload = () => {
    setIsDownloading(true);
    fetchQuery(
      QUERY_KEYS.SPV_INVESTMENT_DOCUMENTS.list(spvInvestment.apiId, {}, ["download_zip_file"]),
      { rawData: true }
    ).then(queryData => {
      var element = document.createElement("a");
      element.setAttribute("href", "data:text/plain;base64," + queryData);
      element.setAttribute("download", `${spvInvestment.investmentId} Deal File.zip`);

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();
      setIsDownloading(false);
      mpTrack("bulk download of spv investor documents", {
        "spv class": getRelatedId(spvInvestment.spvClass),
      });
    });
  };

  const menuItems = [
    {
      text: "View client profile",
      linkType: LinkTypes.LOCAL_LINK,
      url: `/clients/${spvInvestment.source}/`,
    },
    { text: "Upload investor document", onClick: () => setIsUploadModalOpen(true) },
    { text: "Download documents (.zip)", onClick: bulkDownload, disabled: isDownloading },
  ];

  const [menu, menuRef, toggleMenu] = useAnchoredMenu({ menuItems });

  return (
    <StyledCell>
      {isUploadModalOpen && (
        <SPVInvestmentDocumentsFormWithId
          spvInvestmentId={spvInvestment.apiId}
          closeModal={() => setIsUploadModalOpen(false)}
        />
      )}
      <IconButton icon={DotsVerticalIcon} ref={menuRef} onClick={toggleMenu} />
      {ReactDOM.createPortal(menu, document.body)}
    </StyledCell>
  );
};

DropdownCell.propTypes = {
  value: PropTypes.shape({
    apiId: PropTypes.number.isRequired,
    spvClass: PropTypes.array.isRequired,
    investmentId: PropTypes.string.isRequired,
    source: PropTypes.string.isRequired,
  }).isRequired,
};

export default DropdownCell;

import React from "react";

import { RadioGroup, InputRow } from "forms/FormComponents";
import { DIRECT, SPV, FORWARD, INTERNAL_SPV } from "utils/constants";

const options = [
  {
    label: DIRECT,
    value: DIRECT,
  },
  {
    label: SPV,
    value: SPV,
  },
  {
    label: FORWARD,
    value: FORWARD,
  },
  {
    label: INTERNAL_SPV,
    value: INTERNAL_SPV,
  },
];

const ConnectionTypeField = () => (
  <InputRow>
    <RadioGroup label="Select Connection Type" items={options} name="tradeType" />
  </InputRow>
);

export default ConnectionTypeField;

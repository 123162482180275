import { DateTime } from "luxon";
import { CheckboxCell, CheckboxHeader, BadgeCell } from "yuka";

import DropdownCell from "./DropdownCell";
import PinCell from "./PinCell";
import PreviewCell from "./PreviewCell";
import UploadCell from "./UploadCell";

const CheckboxColumn = tableName => ({
  id: `${tableName}_dealFileDocsSelected`,
  accessor: "selected",
  header: `${tableName}_dealFileDocsSelected`,
  width: 50,
  align: "center",
  cellRenderer: CheckboxCell,
  headerRenderer: CheckboxHeader,
  useFixedWidth: true,
});

const UploadColumn = {
  id: "upload",
  accessor: document => document,
  width: 50,
  useFixedWidth: true,
  header: "",
  sortable: false,
  renderOverflow: true,
  cellRenderer: UploadCell,
};
const DropdownColumn = {
  id: "dropdown",
  accessor: document => document,
  width: 50,
  useFixedWidth: true,
  header: "",
  sortable: false,
  renderOverflow: true,
  cellRenderer: DropdownCell,
};

const PinColumn = {
  id: "isPinned",
  accessor: document => document,
  width: 50,
  useFixedWidth: true,
  header: "",
  sortable: false,
  renderOverflow: true,
  cellRenderer: PinCell,
};

const NameColumn = {
  id: "name",
  accessor: "name",
  width: 28,
  header: "Document Name",
  sortable: true,
  sticky: true,
  cellRenderer: PreviewCell,
};

const ClientSourceColumn = {
  id: "sourceName",
  accessor: "sourceName",
  width: 28,
  header: "Client Source",
  sortable: false,
};

const CategoryColumn = {
  id: "category",
  accessor: "category",
  width: 27,
  header: "Category",
  sortable: true,
  cellRenderer: BadgeCell,
};

const ExpirationColumn = {
  id: "expiresDate",
  accessor: "expiresDate",
  width: 17,
  header: "Expiration",
  sortable: true,
  emptyPlaceholder: "--",
  cellRenderer: ({ value: expiresDate }) =>
    expiresDate ? DateTime.fromISO(expiresDate).toLocaleString() : "--",
};

const columns = [
  PinColumn,
  NameColumn,
  CategoryColumn,
  ExpirationColumn,
  UploadColumn,
  DropdownColumn,
];

const actionColumns = [CheckboxColumn(""), PinColumn, NameColumn, CategoryColumn, ExpirationColumn];

const connectionColumns = tableName => [
  CheckboxColumn(tableName),
  NameColumn,
  ClientSourceColumn,
  CategoryColumn,
  ExpirationColumn,
  UploadColumn,
  DropdownColumn,
];

export { columns, connectionColumns, actionColumns, PinColumn };

import React, { useContext } from "react";
import PropTypes from "prop-types";
import { DateTime } from "luxon";
import { Form } from "react-final-form";
import styled from "styled-components";

import { ColorPalette, FormModal } from "yuka";

import { getRelatedObj, useWrite, useDelete, QUERY_KEYS } from "api";
import { Input, InputRow, Checkbox } from "forms/FormComponents";
import { required } from "utils/form/inputValidations";
import { mpTrack } from "utils/mixpanel";
import LoadingSpinner from "utils/LoadingSpinner";
import RelationshipsContext from "utils/RelationshipsContext";

import AlertFieldSet from "./AlertFieldSet";

const StyledInputRow = styled(InputRow)`
  padding-bottom: 16px;
  border-bottom: 1px solid ${ColorPalette.white30};
`;

const EditTaskReminderForm = props => {
  const { onSubmit } = useWrite(QUERY_KEYS.TASKS.detail(props.task.apiId));
  const { onSubmit: apiDelete } = useDelete(QUERY_KEYS.TASKS.detail(props.task.apiId));
  const wrappedOnSubmit = (values, ...params) => {
    const cleanedValues = {
      ...values,
      resolvedOn: values.resolvedOn ? DateTime.fromJSDate(new Date()).toISO() : null,
      sendReminderOn: values.hasAlert ? values.sendReminderOn : null,
      sendReminderTo: values.hasAlert ? values.sendReminderTo : null,
    };
    onSubmit(cleanedValues, ...params).then(() => {
      mpTrack("add task alert");
      props.onClose();
    });
  };
  const relationships = useContext(RelationshipsContext);

  const initialValues = React.useMemo(() => {
    const author = getRelatedObj(props.task.author, relationships);
    const sendReminderTo = getRelatedObj(props.task.sendReminderTo, relationships);

    let initialValues = {
      ...props.task,
      hasAlert: Boolean(props.task.sendReminderOn),
      sendReminderOn: props.task.sendReminderOn
        ? DateTime.fromISO(props.task.sendReminderOn).toJSDate()
        : null,
      author: author,
      sendReminderTo: sendReminderTo,
    };
    delete initialValues.connection;
    return initialValues;
  }, [props.task, relationships]);
  return (
    <Form onSubmit={wrappedOnSubmit} initialValues={initialValues}>
      {({ submitting, handleSubmit }) => (
        <FormModal
          onClose={props.onClose}
          submitText="Save"
          altText="Delete Task"
          onClickAlt={apiDelete}
          onSubmit={handleSubmit}
          title="Edit task"
        >
          {submitting && <LoadingSpinner />}
          <StyledInputRow>
            <Checkbox name="resolvedOn" />
            <Input name="text" placeholder="Enter text" validate={[required]} />
          </StyledInputRow>
          <AlertFieldSet />
        </FormModal>
      )}
    </Form>
  );
};

EditTaskReminderForm.propTypes = {
  task: PropTypes.shape({
    apiId: PropTypes.string,
    resolvedOn: PropTypes.string,
    author: PropTypes.arrayOf(PropTypes.string),
    connection: PropTypes.arrayOf(PropTypes.string),
    sendReminderTo: PropTypes.arrayOf(PropTypes.string),
    sendReminderOn: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default EditTaskReminderForm;

import styled from "styled-components";
import PropTypes from "prop-types";
import { Card, CardStyles, Table } from "yuka";

import { QUERY_KEYS, useFetch } from "api";
import RelationshipsContext from "utils/RelationshipsContext";

import { useTradingGroupsTableConfig, columns } from "./tradingGroupsTableConfig";

const StyledContainer = styled.div`
  height: 100%;
  min-height: 0;
`;

const StyledCard = styled(Card)`
  height: fit-content;
  width: 100%;
`;

/**
 * Table of child source profiles of a given source.
 * Used for funds and Non Subscriber broker dealers
 * @returns {JSX.Element}
 */
const TradingGroupsTab = ({ source }) => {
  const queryParams = {
    include: "source,zxRepresentatives",
    "filter[parent]": source.searchId,
  };
  const {
    isError,
    isLoading,
    data: children,
    relationships,
  } = useFetch(QUERY_KEYS.CLIENT_PROFILES.list({ ...queryParams }));

  const { sortState, onRowClick, onSort } = useTradingGroupsTableConfig();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>An error has occurred</div>;
  }

  return (
    <StyledCard title="Trading Groups" cardStyle={CardStyles.SECTIONED}>
      <StyledContainer>
        <RelationshipsContext.Provider value={relationships}>
          <Table
            usePercentageColumnWidths
            emptyTablePlaceholder="No trading groups"
            sortState={sortState}
            onRowClick={onRowClick}
            onSort={onSort}
            data={children || []}
            columns={columns}
          />
        </RelationshipsContext.Provider>
      </StyledContainer>
    </StyledCard>
  );
};

TradingGroupsTab.propTypes = {
  source: PropTypes.shape({
    name: PropTypes.string,
    category: PropTypes.string,
    searchId: PropTypes.number,
  }).isRequired,
};

export default TradingGroupsTab;

import { useState } from "react";
import PropTypes from "prop-types";
import { Button, DownloadIcon, LinkIcon, UploadIcon } from "yuka";
import styled from "styled-components";

import { fetchQuery } from "api";
import { mpTrack } from "utils/mixpanel";
import LinkDealFileDocumentForm from "forms/LinkDealFileDocumentForm";
import { ConnectionDocumentsForm } from "forms/DocumentsForm";
import LoadingSpinner from "utils/LoadingSpinner";

const StyledLoadingSpinner = styled.div`
  margin: auto 0 auto auto;
`;

const DealFileFolderActions = ({ connection, selectedDocuments, title, queryKeys }) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [isLinkModalOpen, setLinkModalOpen] = useState(false);
  const [showUploadForm, setShowUploadForm] = useState(false);

  const bulkDownload = () => {
    setIsDownloading(true);
    let document_ids = [];
    selectedDocuments.map(doc => document_ids.push(doc.apiId));
    fetchQuery(
      queryKeys.list(connection.tradeId, { document_ids: document_ids }, ["download_zip_file"]),
      { rawData: true }
    ).then(queryData => {
      var element = document.createElement("a");
      element.setAttribute("href", "data:text/plain;base64," + queryData);
      element.setAttribute("download", `${connection.tradeId} ${title}.zip`);

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();
      setIsDownloading(false);
      mpTrack("bulk download of selected deal file documents", { "trade id": connection.tradeId });
    });
  };

  return (
    <>
      {selectedDocuments.length > 0 && (
        <>
          <Button leadingIcon={DownloadIcon} onClick={bulkDownload}>
            Download selected
          </Button>
          <Button leadingIcon={LinkIcon} onClick={() => setLinkModalOpen(true)}>
            Link to Profile
          </Button>
        </>
      )}
      <Button
        onClick={() => setShowUploadForm(true)}
        leadingIcon={UploadIcon}
        disabled={connection.isDealFileLocked}
      >
        Upload Document
      </Button>
      {isDownloading && (
        <StyledLoadingSpinner>
          <LoadingSpinner showContainer={false} />
        </StyledLoadingSpinner>
      )}
      {isLinkModalOpen && (
        <LinkDealFileDocumentForm
          closeModal={() => setLinkModalOpen(false)}
          connection={connection}
          documents={selectedDocuments}
        />
      )}
      {showUploadForm && (
        <ConnectionDocumentsForm
          queryKeys={queryKeys}
          closeModal={() => setShowUploadForm(false)}
        />
      )}
    </>
  );
};

DealFileFolderActions.propTypes = {
  connection: PropTypes.shape({
    tradeId: PropTypes.string,
    isDealFileLocked: PropTypes.bool,
  }).isRequired,
  isLoading: PropTypes.bool,
  selectedDocuments: PropTypes.arrayOf(
    PropTypes.shape({
      apiId: PropTypes.string,
      file: PropTypes.string,
    })
  ).isRequired,
  title: PropTypes.string.isRequired,
  queryKeys: PropTypes.shape({
    list: PropTypes.func.isRequired,
  }).isRequired,
};

export default DealFileFolderActions;

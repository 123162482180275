import { ColorPalette, BadgeCell, IconCell, AlertTriangleIcon } from "yuka";
import { DateTime } from "luxon";

import { EXECUTION_PHASE_BADGE_MAPPING } from "utils/constants";
import PreviewCell from "templates/DocumentsTable/PreviewCell";
import DropdownCell from "./DropdownCell";
import UploadCell from "./UploadCell";
import RepCell from "./RepCell";

const TradeIdColumn = {
  accessor: "tradeId",
  width: 120,
  header: "Trade ID",
};

const CompanyColumn = {
  id: "company",
  width: 120,
  accessor: "companyName",
  header: "Company",
};

const TaskColumn = {
  accessor: "text",
  width: 350,
  header: "Task",
};

const DateColumn = {
  accessor: "sendReminderOn",
  width: 150,
  header: "Alert Date",
};

const RepColumn = {
  id: "representative",
  accessor: task => task,
  width: 140,
  header: "Representative",
  cellRenderer: RepCell,
};

const PhaseColumn = {
  id: "phase",
  accessor: "phase",
  width: 150,
  header: "Phase",
  badgeMapping: EXECUTION_PHASE_BADGE_MAPPING,
  cellRenderer: BadgeCell,
};

const taskColumns = [TradeIdColumn, CompanyColumn, TaskColumn, DateColumn, RepColumn, PhaseColumn];

const DocumentAlertColumn = {
  id: "iconCell",
  header: "",
  width: 50,
  useFixedWidth: true,
  sortable: false,
  getIcon: ({ row }) => {
    const expiryDays = DateTime.local()
      .startOf("day")
      .diff(DateTime.fromISO(row.original.expiresDate).startOf("day"), "days").days;
    let color = ColorPalette.white80;
    if (expiryDays >= 0) {
      color = ColorPalette.sell;
    } else if (expiryDays >= -7) {
      color = ColorPalette.alert;
    }
    return <AlertTriangleIcon color={color} />;
  },
  cellRenderer: IconCell,
};

const ClientColumn = {
  id: "client",
  width: 28,
  header: "Client",
  accessor: "sourceName",
  sortable: true,
};

const DocumentColumn = {
  id: "document",
  width: 28,
  header: "Document",
  accessor: "name",
  sortable: true,
  cellRenderer: PreviewCell,
};

const CategoryColumn = {
  id: "category",
  accessor: "category",
  width: 27,
  header: "Category",
  sortable: true,
  cellRenderer: BadgeCell,
};

const ExpirationColumn = {
  id: "expiresDate",
  accessor: "expiresDate",
  width: 17,
  header: "Expiration",
  sortable: true,
  emptyPlaceholder: "--",
  cellRenderer: ({ value: expiresDate }) =>
    expiresDate ? DateTime.fromISO(expiresDate).toLocaleString() : "--",
};

const UploadColumn = {
  id: "upload",
  accessor: document => document,
  width: 50,
  useFixedWidth: true,
  header: "",
  sortable: false,
  renderOverflow: true,
  cellRenderer: UploadCell,
};
const DropdownColumn = {
  id: "dropdown",
  accessor: document => document,
  width: 50,
  useFixedWidth: true,
  header: "",
  sortable: false,
  renderOverflow: true,
  cellRenderer: DropdownCell,
};

const documentColumns = [
  UploadColumn,
  DropdownColumn,
  DocumentAlertColumn,
  ClientColumn,
  DocumentColumn,
  CategoryColumn,
  ExpirationColumn,
];

export { taskColumns, documentColumns };

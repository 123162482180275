import { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NavigationItem } from "yuka";

import { authenticate, isAuthenticated, logout } from "api/authenticate";
import { GOOGLE_OAUTH_CLIENT_ID } from "api/localSettings";

const OneTapPrompt = () => {
  const navigate = useNavigate();
  const ref = useRef();
  const [google, setGoogle] = useState();
  const [authenticated, setAuthenticated] = useState(isAuthenticated());
  const [autoLogin, setAutoLogin] = useState(true);

  const onLogout = () => {
    logout();
    setAuthenticated(false);
    setAutoLogin(false);
  };

  useEffect(() => {
    if (google) {
      return;
    }

    const id = setInterval(() => {
      if (typeof window !== "undefined" && window.google) {
        setGoogle(window.google);
      }
    }, 100);

    return () => {
      clearInterval(id);
    };
  }, [google]);

  useEffect(() => {
    if (!google) {
      return;
    }

    google.accounts.id.initialize({
      client_id: GOOGLE_OAUTH_CLIENT_ID,
      callback: res =>
        authenticate(res.credential)
          .then(res => {
            setAuthenticated(true);
            return res;
          })
          .catch(() => {
            navigate("/401/");
          }),
      context: "signin",
      ux_mode: "popup",
      auto_select: true,
      use_fedcm_for_prompt: true,
    });
    if (ref.current) {
      google.accounts.id.renderButton(ref.current, {
        theme: "outline",
        size: "medium",
        shape: "rectangle",
        type: "standard",
        text: "signin_with",
        width: 210,
      });
    }
    if (!authenticated && autoLogin) {
      google.accounts.id.prompt();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, google, authenticated, autoLogin]);

  return authenticated ? (
    <NavigationItem className="g_id_signout" onClick={onLogout}>
      Log Out
    </NavigationItem>
  ) : (
    <NavigationItem>
      <div ref={ref} />
    </NavigationItem>
  );
};

export default OneTapPrompt;

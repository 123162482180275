import PropTypes from "prop-types";
import { Badge } from "yuka";

import {
  DEAL_FILE_STATUS_BADGE_MAPPING,
  EXECUTION_DEAL_FILE_STATUS_LABEL_MAPPING,
} from "utils/constants";

const DealFileStatusBadge = ({ connection }) => (
  <Badge badgeStyle={DEAL_FILE_STATUS_BADGE_MAPPING[connection.dealFileStatus]}>
    {EXECUTION_DEAL_FILE_STATUS_LABEL_MAPPING[connection.dealFileStatus]}
  </Badge>
);

DealFileStatusBadge.propTypes = {
  connection: PropTypes.shape({
    dealFileStatus: PropTypes.string.isRequired,
  }).isRequired,
};

export default DealFileStatusBadge;

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-final-form";
import styled from "styled-components";

import { FinalFormField, StyledHeadline2 } from "yuka";

import {
  DatePicker,
  DelimitedNumberInput,
  Input,
  InputRow,
  RadioGroup,
  Select,
} from "forms/FormComponents";
import SecurityField from "forms/fields/SecurityField";
import {
  isInteger,
  isNotNegative,
  isPositive,
  isValidDecimal,
  maxValueTicket,
  required,
} from "utils/form/inputValidations";
import { useFormValue } from "utils/hooks/form";

import {
  commissionOptions,
  expirationOptions,
  isContingentOnQuantityOptions,
  isSolicitedOptions,
  yesNoOptions,
} from "./constants";
import { loadCompanyOptions } from "./loadCompanyOptions";

const StyledContainer = styled.div`
  width: 560px;
`;

const StyledHeadline = styled(StyledHeadline2)`
  margin-bottom: 24px;
`;

const StyledInputRow = styled(InputRow)`
  align-items: end;
`;

const StyledExpirationDateText = styled.span`
  margin: 0px 0px 8px 8px;
`;

const ClientFirmOrderDetails = ({ isBuy }) => {
  const { change } = useForm();
  const isContingentOnQuantity = useFormValue("isContingentOnQuantity");
  const hasExpirationDate = useFormValue("hasExpirationDate");
  const isCompanyAffiliate = useFormValue("isCompanyAffiliate");
  const isCompanyInsider = useFormValue("isCompanyInsider");
  const company = useFormValue("company", null);

  useEffect(() => {
    if (hasExpirationDate === false) {
      change("expirationDate", null);
    }
    if (isContingentOnQuantity === true) {
      change("minimumQuantity", null);
    }
  }, [change, hasExpirationDate, isContingentOnQuantity]);

  return (
    <StyledContainer>
      <StyledHeadline>{isBuy ? "Buyer" : "Seller"} Firm Order Details</StyledHeadline>
      <InputRow>
        <FinalFormField
          type="select"
          loadOptions={loadCompanyOptions}
          name="company"
          placeholder="Issuing Company"
          label="Company"
          validate={[required]}
        />
      </InputRow>
      <InputRow>
        <FinalFormField
          component={SecurityField}
          type="custom-block"
          name="security"
          companyId={company?.apiId || undefined}
        />
      </InputRow>
      <StyledInputRow>
        <DelimitedNumberInput
          title="Price per share / unit (USD)"
          name="pricePerShare"
          placeholder="e.g. 10.00"
          validate={[isNotNegative, isValidDecimal]}
        />
        <DelimitedNumberInput
          name="shareQuantity"
          placeholder="e.g. 100000"
          validate={[maxValueTicket, isPositive, isInteger]}
          title="Quantity (# of shares / units)"
        />
      </StyledInputRow>
      <StyledInputRow>
        <DelimitedNumberInput
          title="Commission"
          name="commissionAmount"
          placeholder="e.g. 1.0"
          validate={[isNotNegative, isValidDecimal, required]}
        />
        <Select
          title="Commission Method"
          name="commissionType"
          options={commissionOptions}
          placeholder="E.g. Price per share, % of total"
          noSort
          validate={[required]}
        />
      </StyledInputRow>
      <InputRow>
        <RadioGroup
          label="Contingency on Quantity (optional)"
          items={isContingentOnQuantityOptions}
          name="isContingentOnQuantity"
        />
      </InputRow>
      {isContingentOnQuantity === false && (
        <InputRow>
          <DelimitedNumberInput
            title="Min quantity (# of shares / units) (optional)"
            name="minimumQuantity"
            placeholder="e.g. 100000"
            validate={[isNotNegative, isInteger]}
          />
        </InputRow>
      )}
      <InputRow>
        <DatePicker
          title="Effective date (MM / DD / YYYY) (optional)"
          name="effectiveDate"
          placeholder="e.g. 01/01/2023"
        />
      </InputRow>
      <InputRow>
        <RadioGroup
          label="Expiration (optional)"
          items={expirationOptions}
          name="hasExpirationDate"
        />
      </InputRow>
      {hasExpirationDate && (
        <StyledInputRow>
          <DatePicker
            title="Expiration Date (MM / DD / YYYY) (optional)"
            name="expirationDate"
            placeholder="e.g. 01/01/2023"
            minDate={new Date()}
          />
          <StyledExpirationDateText>@ 4:00pm ET</StyledExpirationDateText>
        </StyledInputRow>
      )}
      <InputRow>
        <RadioGroup
          label="How was this order initiated? (optional)"
          items={isSolicitedOptions}
          name="isSolicited"
        />
      </InputRow>
      <InputRow>
        <RadioGroup
          label={`${isBuy ? "Buyer" : "Seller"} is an affiliate of the company? (optional)`}
          items={yesNoOptions}
          name="isCompanyAffiliate"
        />
      </InputRow>
      <InputRow>
        <RadioGroup
          label={`${isBuy ? "Buyer" : "Seller"} is a company insider? (optional)`}
          items={yesNoOptions}
          name="isCompanyInsider"
        />
      </InputRow>
      {(isCompanyAffiliate || isCompanyInsider) && (
        <InputRow>
          <Input
            name="currentPosition"
            title="Current Position (optional)"
            placeholder="e.g. Founder CEO"
          />
        </InputRow>
      )}
    </StyledContainer>
  );
};

ClientFirmOrderDetails.propTypes = {
  isBuy: PropTypes.bool.isRequired,
};

export default ClientFirmOrderDetails;

import PropTypes from "prop-types";

import { QUERY_KEYS } from "api";
import {
  SPVDocumentsForm,
  SPVClassDocumentsForm,
  SPVInvestmentDocumentsForm,
  ClientDocumentsForm,
  ConnectionDocumentsForm,
} from "forms/DocumentsForm";

import DocumentsTable from "./component";
import { actionColumns, columns, connectionColumns } from "./columns";

export const ClientDocumentsTable = props => (
  <DocumentsTable
    {...props}
    queryKeys={QUERY_KEYS.CLIENT_DOCUMENTS}
    parentQueryKeys={QUERY_KEYS.CLIENT_PROFILES}
    documentsForm={ClientDocumentsForm}
    columns={columns}
    tableName="CLIENT_DOCUMENTS"
  />
);

const parentDocsFetch = (pk, queryParams) =>
  QUERY_KEYS.CLIENT_DOCUMENTS.detail(pk, ["with_parent_docs"], [], queryParams);
export const LinkDocumentsTable = props => (
  <DocumentsTable
    {...props}
    queryParams={{ "filter[isArchived]": false }}
    queryKeys={{ list: parentDocsFetch, detail: () => {} }}
    documentsForm={null}
    columns={actionColumns}
    tableName="LINK_DOCUMENTS"
    actionsDisabled
  />
);

export const SPVDocumentsTable = props => (
  <DocumentsTable
    {...props}
    queryKeys={QUERY_KEYS.SPV_DOCUMENTS}
    documentsForm={SPVDocumentsForm}
    columns={columns}
    tableName="SPV_DOCUMENTS"
  />
);

export const SPVClassDocumentsTable = props => (
  <DocumentsTable
    {...props}
    queryKeys={QUERY_KEYS.SPV_CLASS_DOCUMENTS}
    documentsForm={SPVClassDocumentsForm}
    columns={columns}
    tableName="SPV_CLASS_DOCUMENTS"
  />
);

export const ConnectionDocumentsTable = props => (
  <DocumentsTable
    {...props}
    parentQueryKeys={QUERY_KEYS.CONNECTIONS}
    documentsForm={ConnectionDocumentsForm}
    columns={connectionColumns}
    actionsDisabled={props.connection.isDealFileLocked}
  />
);

ConnectionDocumentsTable.propTypes = {
  connection: PropTypes.shape({
    isDealFileLocked: PropTypes.bool,
  }).isRequired,
};

export const SPVInvestmentDocumentsTable = props => (
  <DocumentsTable
    {...props}
    queryParams={{ "filter[isArchived]": false }}
    queryKeys={QUERY_KEYS.SPV_INVESTMENT_DOCUMENTS}
    documentsForm={SPVInvestmentDocumentsForm}
    columns={columns}
    tableName="SPV_INVESTMENT_DOCUMENTS"
  />
);

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { ColorPalette, Fonts, HyperLink, LinkTypes, StyledCaption2, StyledHeadline1 } from "yuka";

import { SPV_TYPE_MASTER } from "./constants";

const StyledHeader = styled.div`
  display: flex;
  margin-top: 16px;
  padding-bottom: 24px;
  border-bottom: 1px solid ${ColorPalette.white15};
  flex-direction: column;
`;

const StyledHeaderFirstRow = styled.div`
  margin-bottom: 4px;
`;

const SPVProfileHeader = ({ spv, parent }) => (
  <>
    <StyledCaption2>
      <HyperLink url="../" linkType={LinkTypes.LOCAL_LINK}>
        SPVs
      </HyperLink>
      {parent && (
        <>
          <span> / </span>
          <HyperLink url={`/spvs/${parent.apiId}/`} linkType={LinkTypes.LOCAL_LINK}>
            {parent.name}
          </HyperLink>
        </>
      )}
      <span> / {spv.name}</span>
    </StyledCaption2>
    <StyledHeader>
      <StyledHeaderFirstRow>
        <StyledHeadline1>{spv.name}</StyledHeadline1>
      </StyledHeaderFirstRow>
      {spv.spvType !== SPV_TYPE_MASTER && (
        <span>
          <StyledCaption2>
            {"Representing shares in: "}
            <Fonts.Caption2theme80>{spv.companyName}</Fonts.Caption2theme80>
          </StyledCaption2>
        </span>
      )}
    </StyledHeader>
  </>
);

SPVProfileHeader.propTypes = {
  spv: PropTypes.shape({
    name: PropTypes.string.isRequired,
    spvType: PropTypes.string.isRequired,
    companyName: PropTypes.string,
    parentName: PropTypes.string,
  }).isRequired,
  parent: PropTypes.shape({
    apiId: PropTypes.string,
    name: PropTypes.string,
  }),
  company: PropTypes.shape({
    apiId: PropTypes.string,
  }),
};

export default SPVProfileHeader;

import { useCallback } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { FloatingNavigation, NavigationItem, useTabRoute } from "yuka";

import { QUERY_KEYS, getRelatedObj, useFetch } from "api";
import { MixpanelSourceContext, mpTrack } from "utils/mixpanel";
import { FrontendError, Loading } from "templates/ErrorPages";
import NotesCard from "templates/NotesCard";

import {
  DOCUMENTS,
  SERIES,
  CLASSES,
  NOTES,
  SETTINGS,
  INVESTORS,
  ALL_TABS,
  getTabsForProfile,
} from "./constants";
import DocumentsCard from "./DocumentsCard";
import FundsCard from "./FundsCard";
import SPVProfileHeader from "./SPVProfileHeader";
import SettingsCard from "./SettingsCard";
import InvestorsCard from "./InvestorsCard";

const StyledClientProfile = styled.div`
  display: flex;
  margin-top: 16px;
  gap: 16px;
  height: 100%;
  min-height: 0;
`;

const StyledSideBar = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const SPVProfileView = () => {
  const [currentTab, setCurrentTab] = useTabRoute(ALL_TABS, "tab");
  const { id } = useParams();

  const {
    isError,
    isLoading,
    data: spv,
    relationships,
  } = useFetch(QUERY_KEYS.SPV.detail(id, [], { include: "parent,company" }));

  const mpTrackTabChange = useCallback(
    tab => {
      setCurrentTab(tab);
      mpTrack("toggle SPV profile tab", { tab });
    },
    [setCurrentTab]
  );

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <FrontendError />;
  }

  const parent = getRelatedObj(spv.parent, relationships);
  const company = getRelatedObj(spv.company, relationships);
  const TAB_NAMES = getTabsForProfile(spv.spvType);

  return (
    <MixpanelSourceContext.Provider value="spv profile">
      <SPVProfileHeader spv={spv} parent={parent} company={company} />
      <StyledClientProfile>
        <StyledSideBar>
          <FloatingNavigation>
            {TAB_NAMES.map(tab => (
              <NavigationItem
                key={tab}
                onClick={() => mpTrackTabChange(tab)}
                selected={tab === currentTab}
              >
                {tab}
              </NavigationItem>
            ))}
          </FloatingNavigation>
        </StyledSideBar>
        {[SERIES, CLASSES].includes(currentTab) && <FundsCard spv={spv} />}
        {currentTab === INVESTORS && <InvestorsCard spv={spv} />}
        {currentTab === DOCUMENTS && <DocumentsCard spv={spv} />}
        {currentTab === SETTINGS && <SettingsCard company={company} spv={spv} />}
        {currentTab === NOTES && <NotesCard queryKey={QUERY_KEYS.SPV_NOTES} apiId={spv.apiId} />}
      </StyledClientProfile>
    </MixpanelSourceContext.Provider>
  );
};

export default SPVProfileView;

import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FontColors, ListItem, ListStyles, StyledCaption1 } from "yuka";

import { QUERY_KEYS, getRelatedId, useFetch } from "api";
import LinkParticipantDocsForm from "forms/LinkParticipantDocsForm";

const StyledText = styled(StyledCaption1)`
  white-space: nowrap;
  ${props => props.$color};
`;

/**
 * List item for participants with documents in state
 */
const ParticipantListItem = ({ connection, name, participantType, source }) => {
  const [isEditing, setIsEditing] = useState(false);
  const {
    isLoading,
    isError,
    data: documents,
  } = useFetch(QUERY_KEYS.CLIENT_DOCUMENTS.detail(source.apiId, ["with_parent_docs"]));
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>An error has occurred</div>;
  }

  let text = "";

  const documentsIds = documents.map(document => document.apiId);
  const connectionDocumentsIds = connection.documents.map(document => getRelatedId(document));
  const linkedDocs = documentsIds.filter(value => connectionDocumentsIds.includes(value));
  const participantDocs = documentsIds.length;
  if (linkedDocs.length) {
    text = (
      <StyledText
        $color={FontColors.buy}
      >{`Linked (${linkedDocs.length} of ${participantDocs})`}</StyledText>
    );
  } else {
    text = <StyledText>Unlinked</StyledText>;
  }

  return (
    <>
      {isEditing && (
        <LinkParticipantDocsForm
          connection={connection}
          documents={documents}
          onClose={() => setIsEditing(false)}
          name={name}
          participantType={participantType}
          source={source}
        />
      )}
      <ListItem
        onClick={() => setIsEditing(true)}
        listStyle={ListStyles.TWO_LINE}
        text={name}
        subtext={participantType}
        trailingContent={text}
      />
    </>
  );
};

ParticipantListItem.propTypes = {
  connection: PropTypes.shape({
    documents: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
    tradeId: PropTypes.string,
  }).isRequired,
  participantType: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  source: PropTypes.shape({
    apiId: PropTypes.string,
    sourceType: PropTypes.string,
  }).isRequired,
};

export default ParticipantListItem;

import {
  BadgeCell,
  CurrencyCell,
  DateCell,
  CheckboxCell,
  CheckboxHeader,
  IconButtonCell,
  LinkExternalIcon,
} from "yuka";

import { KYC_BADGE_MAPPING } from "utils/constants";

import {
  SPVInviteMarketOperatorCell,
  SPVInvestmentMarketOperatorCell,
} from "./MarketOperatorCells";
import InvestmentOnboardingRequestCell from "./InvestmentOnboardingRequestCell";
import DropdownCell from "./DropdownCell";

const NameColumn = {
  id: "name",
  accessor: "name",
  width: 25,
  header: "Name",
  sortable: true,
};

const EmailColumn = {
  id: "email",
  accessor: "email",
  width: 25,
  header: "Email",
  sortable: true,
};

const SPVInviteMarketOperatorColumn = {
  id: "marketOperator",
  accessor: "marketOperator",
  width: 25,
  header: "CC Market Operator",
  sortable: true,
  cellRenderer: SPVInviteMarketOperatorCell,
};

const InviteSentOnColumn = {
  id: "sentOn",
  accessor: "sentOn",
  width: 25,
  header: "Invite Sent On",
  sortable: true,
  cellRenderer: DateCell,
};

const inviteSentColumns = [
  NameColumn,
  EmailColumn,
  SPVInviteMarketOperatorColumn,
  InviteSentOnColumn,
];

const InvestmentIdColumn = {
  id: "investmentId",
  accessor: "investmentId",
  width: 150,
  sortable: true,
  header: "Trade ID",
  sticky: true,
};

const CheckboxColumn = {
  id: "investmentSelected",
  accessor: "selected",
  header: "checkbox",
  width: 50,
  align: "center",
  sticky: true,
  cellRenderer: CheckboxCell,
  headerRenderer: CheckboxHeader,
  useFixedWidth: true,
  sortable: false,
};

const InvestmentNameColumn = {
  id: "sourceProfile.name",
  accessor: "name",
  width: 200,
  header: "Name",
  sortable: true,
  sticky: true,
};

const LinkToProfileColumn = {
  id: "expandProfileColumn",
  width: 65,
  useFixedWidth: true,
  header: "View",
  align: "center",
  sortable: false,
  icon: LinkExternalIcon,
  onClick: ({ navigateToProfile, ...props }) => navigateToProfile(props),
  cellRenderer: IconButtonCell,
  sticky: true,
};

const SPVInvestmentMarketOperatorColumn = {
  id: "marketOperator",
  accessor: onboardingRequest => onboardingRequest,
  width: 200,
  header: "Market Operator",
  sortable: true,
  cellRenderer: SPVInvestmentMarketOperatorCell,
};

const SubscriptionAmountColumn = {
  id: "subscriptionAmount",
  accessor: "subscriptionAmount",
  width: 120,
  header: "Amount",
  sortable: true,
  cellRenderer: CurrencyCell,
};

const SubscriptionAcceptanceDateColumn = {
  id: "subscriptionAcceptanceDate",
  accessor: "subscriptionAcceptanceDate",
  width: 120,
  header: "Closing Date",
  sortable: true,
  cellRenderer: DateCell,
  emptyPlaceholder: "Pending",
};

const KYCStatusColumn = {
  id: "kycStatus",
  accessor: "kycStatus",
  cellRenderer: BadgeCell,
  width: 150,
  badgeMapping: KYC_BADGE_MAPPING,
  header: "KYC Status",
  sortable: false,
};

const OnboardingStatusColumn = {
  id: "onboardingRequest",
  accessor: "onboardingRequest",
  width: 150,
  header: "Document Status",
  cellRenderer: InvestmentOnboardingRequestCell,
  sortable: false,
};

const DropdownColumn = {
  id: "dropdown",
  accessor: spvInvestment => spvInvestment,
  width: 70,
  useFixedWidth: true,
  header: "Docs",
  cellRenderer: DropdownCell,
  sortable: false,
  sticky: true,
};

const SPVInvestmentClassColumn = {
  id: "spvClass.name",
  accessor: "className",
  sortable: true,
  header: "Class",
  width: 150,
};

const pinnedInvestmentColumns = [
  CheckboxColumn,
  InvestmentIdColumn,
  InvestmentNameColumn,
  LinkToProfileColumn,
  DropdownColumn,
];

const investmentStatusColumns = [
  KYCStatusColumn,
  OnboardingStatusColumn,
  SubscriptionAcceptanceDateColumn,
  // Funds received?
  // Fees
  SubscriptionAmountColumn,
  SPVInvestmentMarketOperatorColumn,
];

const investmentColumns = [...pinnedInvestmentColumns, ...investmentStatusColumns];

const investmentColumnsWithClass = [
  ...pinnedInvestmentColumns,
  SPVInvestmentClassColumn,
  ...investmentStatusColumns,
];

export { investmentColumns, investmentColumnsWithClass, inviteSentColumns };

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Card, CardStyles, List, ListItem, ListStyles } from "yuka";
import styled from "styled-components";

import { QUERY_KEYS, useFetch } from "api";
import EditClientDetailsForm from "forms/EditClientDetailsForm";

const StyledCard = styled(Card)`
  height: fit-content;
  width: 100%;
`;

const GeneralSettingsCard = ({ source, sourceProfile }) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const edit = () => setIsEditModalOpen(true);
  const {
    isError,
    isLoading,
    data: sourceData,
  } = useFetch(
    QUERY_KEYS.SOURCES.list({
      "filter[search_id]": sourceProfile.parent ? sourceProfile.parent : [],
    }),
    { enabled: !!sourceProfile.parent }
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>An error has occurred</div>;
  }

  const parent = sourceProfile.parent ? sourceData[0] : null;
  return (
    <StyledCard
      cardStyle={CardStyles.SECTIONED}
      title="General Settings"
      linkText="Edit"
      linkProps={{ onClick: edit }}
    >
      <List divider>
        <ListItem
          listStyle={ListStyles.TWO_LINE}
          text={sourceProfile.sourceType || "--"}
          subtext="Client Type"
        />
        <ListItem
          listStyle={ListStyles.TWO_LINE}
          text={parent?.name || "--"}
          subtext="Source Parent"
        />
        <ListItem
          listStyle={ListStyles.TWO_LINE}
          text={sourceProfile.phone || "--"}
          subtext="Phone"
        />
        <ListItem
          listStyle={ListStyles.TWO_LINE}
          text={sourceProfile.email || "--"}
          subtext="Email"
        />
        <ListItem
          listStyle={ListStyles.TWO_LINE}
          text={sourceProfile.address || "--"}
          subtext="Address"
        />
      </List>
      {isEditModalOpen && (
        <EditClientDetailsForm
          sourceProfile={sourceProfile}
          source={source}
          onClose={() => setIsEditModalOpen(false)}
        />
      )}
    </StyledCard>
  );
};

GeneralSettingsCard.propTypes = {
  source: PropTypes.shape({
    apiId: PropTypes.string,
  }).isRequired,
  sourceProfile: PropTypes.shape({
    sourceType: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    parent: PropTypes.string,
    address: PropTypes.string,
  }).isRequired,
};

export default GeneralSettingsCard;

import { useCallback } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { Table, ColorPalette } from "yuka";
import styled from "styled-components";

import { useInfiniteFetch } from "api";
import BulkUploadDocumentsForm from "forms/BulkUploadDocumentsForm";

import useDocumentsTableConfig from "./useDocumentsTableConfig";
import useDocumentDragUpload from "./useDocumentDragUpload";

const StyledContainer = styled.div`
  height: 100%;
  min-height: 0;
  ${props =>
    props.$dragging
      ? `
    border: 1px dashed ${ColorPalette.white30};
  `
      : ""}
`;

/**
 * Table displaying all documents in the Deal File
 */
const DocumentsTable = ({
  documents: initialDocuments,
  queryKeys,
  columns: columnDefs,
  queryParams: initialQueryParams,
  parentQueryKeys,
  setSelectedDocuments,
  documentsForm,
  tableName,
  pk,
  ...props
}) => {
  const columns = typeof columnDefs === "function" ? columnDefs(tableName) : columnDefs;

  const { id } = useParams();
  const { currentTableSort, onSort, queryParams } = useDocumentsTableConfig(
    tableName,
    initialQueryParams,
    columns
  );

  const {
    data: documents,
    fetchNextPage,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteFetch(queryKeys.list(pk || id, queryParams), {
    initialData: initialDocuments || [],
  });

  const [containerRef, fileInputRef, isDragging] = useDocumentDragUpload(queryKeys, pk || id);

  const onRowSelectStateChange = useCallback(
    data => {
      let newSelectedDocuments = [];
      Object.keys(data).map(rowNumber => {
        newSelectedDocuments.push(documents[rowNumber]);
      });
      setSelectedDocuments?.(newSelectedDocuments);
      return;
    },
    [documents, setSelectedDocuments]
  );

  const table = (
    <Table
      usePercentageColumnWidths
      emptyTablePlaceholder="No documents found"
      data={documents}
      columns={columns}
      onRowSelectStateChange={onRowSelectStateChange}
      queryKeys={queryKeys}
      parentQueryKeys={parentQueryKeys}
      documentsForm={documentsForm}
      paginationFunc={fetchNextPage}
      isPaginationLoading={isFetchingNextPage}
      isLoading={isLoading}
      sortState={currentTableSort}
      onSort={onSort}
      manualSortBy
      {...props}
    />
  );

  return props.actionsDisabled ? (
    table
  ) : (
    <StyledContainer ref={containerRef} $dragging={isDragging}>
      {table}
      <BulkUploadDocumentsForm fileInputRef={fileInputRef} />
    </StyledContainer>
  );
};

DocumentsTable.propTypes = {
  tableName: PropTypes.string.isRequired,
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ),
  parentQueryKeys: PropTypes.shape({
    detail: PropTypes.func,
    list: PropTypes.func,
  }),
  queryKeys: PropTypes.shape({
    detail: PropTypes.func,
    list: PropTypes.func,
  }).isRequired,
  // Required is not disabling edit functions
  documentsForm: PropTypes.func,
  columns: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      })
    ),
    PropTypes.func,
  ]).isRequired,
  queryParams: PropTypes.shape({
    name: PropTypes.string,
    category: PropTypes.arrayOf(PropTypes.string),
  }),
  setSelectedDocuments: PropTypes.func,
  // Override default lookup to use a specific pk
  pk: PropTypes.string,
  actionsDisabled: PropTypes.bool,
};

export default DocumentsTable;

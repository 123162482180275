import {
  CATEGORY_UNSPECIFIED,
  CATEGORY_ONBOARDING,
  CATEGORY_FEE_AGREEMENT,
  CATEGORY_KYC,
  CATEGORY_INVOICE_TEMPLATE,
  CATEGORY_WIRE_INSTRUCTIONS,
  CATEGORY_SHARE_OWNERSHIP,
  CATEGORY_TRANSFER_DOCUMENT,
  CATEGORY_OTHER,
} from "utils/constants";

const CATEGORY_OPTIONS = [
  CATEGORY_UNSPECIFIED,
  CATEGORY_ONBOARDING,
  CATEGORY_FEE_AGREEMENT,
  CATEGORY_KYC,
  CATEGORY_INVOICE_TEMPLATE,
  CATEGORY_WIRE_INSTRUCTIONS,
  CATEGORY_SHARE_OWNERSHIP,
  CATEGORY_TRANSFER_DOCUMENT,
  CATEGORY_OTHER,
];

export { CATEGORY_OPTIONS };

import {
  SUBSCRIBER_SOURCES,
  TRADING_GROUP_SOURCES,
  NON_SUBSCRIBER_SOURCES,
  INDIVIDUAL_SOURCES,
  ENTITY_SOURCES,
  TRUST_SOURCES,
  KYC_APPROVED,
  KYC_EXPIRED,
  KYC_INCOMPLETE,
  KYC_REVIEW,
  KYC_INFORMATION_REQUESTED,
  KYC_INFORMATION_UPDATED,
} from "utils/constants";

const KYC_OPTIONS = [
  { label: KYC_INCOMPLETE, value: KYC_INCOMPLETE },
  { label: KYC_EXPIRED, value: KYC_EXPIRED },
  { label: KYC_REVIEW, value: KYC_REVIEW },
  { label: KYC_APPROVED, value: KYC_APPROVED },
  { label: KYC_INFORMATION_REQUESTED, value: KYC_INFORMATION_REQUESTED },
  { label: KYC_INFORMATION_UPDATED, value: KYC_INFORMATION_UPDATED },
];

const DOCUMENTS = "Documents";
const CONNECTIONS = "Connections";
const TRADING_GROUPS = "Trading Groups";
const MEMBERS = "Members";
const FUNDS = "Funds";
const CONTACTS = "Contacts";
const NOTES = "Notes";
const SETTINGS = "Settings";

const CATEGORY_REP_INDIVIDUAL = "Registered Rep Individual";

const SUBSCRIBER_TABS = [DOCUMENTS, CONNECTIONS, TRADING_GROUPS, CONTACTS, NOTES, SETTINGS];
const TRADING_GROUPS_TABS = [DOCUMENTS, CONNECTIONS, CONTACTS, NOTES, SETTINGS];
const INDIVIDUAL_TABS = [DOCUMENTS, CONNECTIONS, CONTACTS, NOTES, SETTINGS];
const ENTITY_TABS = [DOCUMENTS, CONNECTIONS, FUNDS, CONTACTS, NOTES, SETTINGS];
const TRUST_TABS = [DOCUMENTS, CONNECTIONS, FUNDS, CONTACTS, NOTES, SETTINGS];
const ALL_TAB_NAMES = [DOCUMENTS, TRADING_GROUPS, FUNDS, CONNECTIONS, CONTACTS, NOTES, SETTINGS];

const getTabsForProfile = sourceType => {
  switch (sourceType) {
    case SUBSCRIBER_SOURCES:
      return SUBSCRIBER_TABS;
    case TRADING_GROUP_SOURCES:
    case NON_SUBSCRIBER_SOURCES:
      return TRADING_GROUPS_TABS;
    case INDIVIDUAL_SOURCES:
      return INDIVIDUAL_TABS;
    case ENTITY_SOURCES:
      return ENTITY_TABS;
    case TRUST_SOURCES:
      return TRUST_TABS;
    default: // should never reach the default
      return ALL_TAB_NAMES;
  }
};

export {
  SUBSCRIBER_SOURCES,
  TRADING_GROUP_SOURCES,
  NON_SUBSCRIBER_SOURCES,
  INDIVIDUAL_SOURCES,
  ENTITY_SOURCES,
  TRUST_SOURCES,
  KYC_APPROVED,
  KYC_EXPIRED,
  KYC_INCOMPLETE,
  KYC_REVIEW,
  KYC_INFORMATION_REQUESTED,
  KYC_INFORMATION_UPDATED,
  KYC_OPTIONS,
  DOCUMENTS,
  CONNECTIONS,
  TRADING_GROUPS,
  MEMBERS,
  FUNDS,
  CONTACTS,
  NOTES,
  SETTINGS,
  ALL_TAB_NAMES,
  CATEGORY_REP_INDIVIDUAL,
  getTabsForProfile,
};

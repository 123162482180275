import React from "react";

import { DelimitedNumberInput, Input, InputRow } from "forms/FormComponents";
import { isPositive, isInteger } from "utils/form/inputValidations";

/**
 * Field set for general invoicing settings
 *
 * @returns {Element}
 */
const GeneralInvoicingFieldSet = () => (
  <>
    <InputRow>
      <Input name="partySuffix" title="Party Suffix" placeholder="e.g. ZAN" />
    </InputRow>
    <InputRow>
      <DelimitedNumberInput
        name="defaultDueDate"
        title="Default Due Date (# of business days)"
        placeholder="e.g. 5"
        validate={[isPositive, isInteger]}
      />
    </InputRow>
  </>
);

export default GeneralInvoicingFieldSet;

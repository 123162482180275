import PropTypes from "prop-types";
import { ComponentGroup } from "yuka";

import { QUERY_KEYS } from "api";

import DealFileTable from "./DealFileTable";
import DealFileFilter from "./DealFileFilter";

const DealFile = ({ connection, showComplianceDocs }) => (
  <ComponentGroup>
    <DealFileFilter connection={connection} />
    {showComplianceDocs && (
      <DealFileTable
        connection={connection}
        documents={connection.complianceDocuments}
        tableName="COMPLIANCE_DOCUMENTS"
        title="Compliance Documents"
        queryKeys={QUERY_KEYS.DEAL_FILE_COMPLIANCE_DOCUMENTS}
      />
    )}
    <DealFileTable
      connection={connection}
      documents={connection.transferDocuments}
      tableName="TRANSFER_DOCUMENTS"
      title="Transfer Documents"
      queryKeys={QUERY_KEYS.DEAL_FILE_TRANSFER_DOCUMENTS}
    />
    <DealFileTable
      connection={connection}
      documents={connection.buysideDocuments}
      tableName="BUYSIDE_DOCUMENTS"
      title="Buyside Documents"
      queryKeys={QUERY_KEYS.DEAL_FILE_BUYSIDE_DOCUMENTS}
    />
    <DealFileTable
      connection={connection}
      documents={connection.sellsideDocuments}
      tableName="SELLSIDE_DOCUMENTS"
      title="Sellside Documents"
      queryKeys={QUERY_KEYS.DEAL_FILE_SELLSIDE_DOCUMENTS}
    />
  </ComponentGroup>
);

DealFile.propTypes = {
  connection: PropTypes.shape({
    buysideDocuments: PropTypes.arrayOf(PropTypes.array).isRequired,
    sellsideDocuments: PropTypes.arrayOf(PropTypes.array).isRequired,
    transferDocuments: PropTypes.arrayOf(PropTypes.array).isRequired,
    complianceDocuments: PropTypes.arrayOf(PropTypes.array).isRequired,
    tradeId: PropTypes.string.isRequired,
  }).isRequired,
  showComplianceDocs: PropTypes.bool,
};

DealFile.defaultProps = {
  showComplianceDocs: false,
};

export default DealFile;

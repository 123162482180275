import { useState } from "react";
import {
  Badge,
  Fonts,
  HyperLink,
  LinkTypes,
  ColorPalette,
  Card,
  CardStyles,
  ComponentGroup,
  Button,
  UploadIcon,
  Copy03Icon,
} from "yuka";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { useFetch, useWrite, DataTypes, QUERY_KEYS, getRelatedId, getRelatedObj } from "api";
import { queryClient } from "api/queryClient";
import { KYC_BADGE_MAPPING } from "utils/constants";
import { MixpanelSourceContext } from "utils/mixpanel";
import { expandedMoneyFormat } from "utils/displayFormatUtils";
import RelationshipsContext from "utils/RelationshipsContext";
import { SPVInvestmentDocumentsTable, SPVClassDocumentsTable } from "templates/DocumentsTable";
import { Loading, FrontendError } from "templates/ErrorPages";
import { SPVInvestmentDocumentsForm } from "forms/DocumentsForm";
import LinkSPVInvestorDocsForm from "forms/LinkSPVInvestorDocsForm";

const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 24px 0;
  padding-bottom: 24px;
  border-bottom: 1px solid ${ColorPalette.white15};
`;

const UPLOAD = "UPLOAD";
const IMPORT = "IMPORT";

const SPVInvestmentProfileView = () => {
  const { id } = useParams();
  const [formOpen, setFormOpen] = useState(false);

  const queryKey = QUERY_KEYS.SPV_INVESTMENTS.detail(id, [], { include: "spv" });

  const {
    isLoadingError,
    isError,
    isLoading,
    data: spvInvestment,
    relationships,
  } = useFetch(queryKey);

  const spv = getRelatedObj(spvInvestment?.spv, relationships);

  const { onSubmit: requestKycOnSubmit } = useWrite(
    QUERY_KEYS.SPV.detail(spv?.apiId, ["kyc-investors"]),
    {
      message: "Requested investor compliance approval",
    }
  );

  const requestKyc = () =>
    requestKycOnSubmit({
      apiId: spv.apiId,
      apiType: DataTypes.SPV,
      spvInvestments: [
        {
          apiId: spvInvestment.apiId,
          apiType: DataTypes.SPV_INVESTMENT,
        },
      ],
    }).then(() => {
      queryClient.invalidateQueries(queryKey);
    });

  if (isLoading || isLoadingError) {
    return <Loading />;
  }
  if (isError) {
    return <FrontendError />;
  }

  let fullName = `${spvInvestment.className} of `;
  if (spvInvestment.masterName) {
    fullName += `${spvInvestment.masterName} - `;
  }
  fullName += spvInvestment.spvName;

  return (
    <MixpanelSourceContext.Provider value="spv investment profile">
      <RelationshipsContext.Provider value={relationships}>
        <Fonts.Caption2theme50>
          <HyperLink url="../" linkType={LinkTypes.LOCAL_LINK}>
            SPVs
          </HyperLink>
          {spv.parentId && (
            <>
              <span> / </span>
              <HyperLink url={`/spvs/${spv.parentId}/`} linkType={LinkTypes.LOCAL_LINK}>
                {spv.parentName}
              </HyperLink>
            </>
          )}
          <span> / </span>
          <HyperLink url={`/spvs/${spv.apiId}/`} linkType={LinkTypes.LOCAL_LINK}>
            {spv.name}
          </HyperLink>
          <span> / </span>
          <HyperLink
            url={`/spvs/class/${getRelatedId(spvInvestment.spvClass)}/`}
            linkType={LinkTypes.LOCAL_LINK}
          >
            {spvInvestment.className}
          </HyperLink>
          <span> / {spvInvestment.name}</span>
        </Fonts.Caption2theme50>
        <StyledHeader>
          <Fonts.Headline1theme100>
            ({spvInvestment.investmentId}) {spvInvestment.name}
          </Fonts.Headline1theme100>
          <Fonts.Caption2theme50>
            <span>Investment in: </span>
            <Fonts.Caption2theme80>{fullName}</Fonts.Caption2theme80>
            <span> | Subscription Amount: </span>
            <Fonts.Caption2theme80>
              {expandedMoneyFormat(spvInvestment.subscriptionAmount)}
            </Fonts.Caption2theme80>
            <span> | KYC Status: </span>
            <Badge badgeStyle={KYC_BADGE_MAPPING[spvInvestment.kycStatus]}>
              {spvInvestment.kycStatus}
            </Badge>
          </Fonts.Caption2theme50>
        </StyledHeader>
        <ComponentGroup>
          <Card
            title="Investor Documents"
            cardStyle={CardStyles.SECTIONED}
            headerTrailingContent={
              <>
                <Button onClick={requestKyc}>Request KYC Approval</Button>
                <Button onClick={() => setFormOpen(IMPORT)} leadingIcon={Copy03Icon}>
                  Import Client Documents
                </Button>
                <Button onClick={() => setFormOpen(UPLOAD)} leadingIcon={UploadIcon}>
                  Upload Investor Document
                </Button>
              </>
            }
          >
            <SPVInvestmentDocumentsTable />
          </Card>
          <Card title="SPV/Class Documents" cardStyle={CardStyles.SECTIONED}>
            <SPVClassDocumentsTable
              queryParams={{ "filter[isArchived]": false }}
              actionsDisabled
              pk={getRelatedId(spvInvestment.spvClass)}
            />
          </Card>
        </ComponentGroup>
        {formOpen === UPLOAD && (
          <SPVInvestmentDocumentsForm closeModal={() => setFormOpen(false)} />
        )}
        {formOpen === IMPORT && (
          <LinkSPVInvestorDocsForm
            onClose={() => setFormOpen(false)}
            spvInvestment={spvInvestment}
          />
        )}
      </RelationshipsContext.Provider>
    </MixpanelSourceContext.Provider>
  );
};

export default SPVInvestmentProfileView;

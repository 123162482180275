import React from "react";
import PropTypes from "prop-types";
import { HyperLink, LinkTypes } from "yuka";

const ClientProfileLink = ({ name, url }) => (
  <HyperLink url={url} linkType={LinkTypes.LOCAL_LINK}>
    {name}
  </HyperLink>
);

ClientProfileLink.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default ClientProfileLink;

import React, { useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { IconButton, useAnchoredMenu, DotsVerticalIcon } from "yuka";

import { fetchQueryResponse, useDelete } from "api";
import { queryClient } from "api/queryClient";
import LinkDealFileDocumentForm from "forms/LinkDealFileDocumentForm";
import downloadFileResponse from "utils/downloadFileResponse";

import { STATUS_ARCHIVED } from "utils/constants";

const StyledCell = styled.div`
  display: flex;
`;

/**
 * Renders a dropdown for the documents table
 *
 * @param {object} prop
 * @returns {JSX.Element}
 */

const DropdownCell = ({
  value: document,
  documentsForm: DocumentsForm,
  queryKeys,
  parentQueryKeys,
  connection,
  actionsDisabled,
}) => {
  const { id } = useParams();
  const { onSubmit: onDelete } = useDelete(queryKeys.detail(id, document.apiId), {
    message: "Successfully archived/removed",
  });
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isLinkModalOpen, setLinkModalOpen] = React.useState(false);
  const editAction = () => setEditModalOpen(true);
  const closeModal = () => {
    setEditModalOpen(false);
  };
  const handleDelete = () => {
    if (confirm(`Are you sure you want to archive this document?`)) {
      onDelete().then(() => {
        if (parentQueryKeys) {
          queryClient.invalidateQueries(parentQueryKeys.detail(id, []));
        }
      });
    }
  };

  const menuItems = [
    {
      text: "Edit details",
      onClick: editAction,
      disabled: actionsDisabled || document.isFromParent,
    },
  ];
  if (document.status !== STATUS_ARCHIVED) {
    menuItems.push({
      text: "Archive",
      onClick: handleDelete,
      disabled: actionsDisabled || document.isFromParent,
    });
  }
  if (!document.sourceName && connection) {
    menuItems.push({ text: "Link to Profile", onClick: () => setLinkModalOpen(true) });
  }
  const download = document => {
    fetchQueryResponse(queryKeys.detail(id, document.apiId, ["download"])).then(data => {
      const documentName =
        document.extension === document.name.split(".").at(-1)
          ? document.name
          : `${document.name}.${document.extension}`;
      downloadFileResponse(data, documentName);
    });
  };
  menuItems.push({ text: "Download", onClick: () => download(document) });

  const [menu, menuRef, toggleMenu] = useAnchoredMenu({ menuItems });

  return (
    <StyledCell>
      {isLinkModalOpen && (
        <LinkDealFileDocumentForm
          closeModal={() => setLinkModalOpen(false)}
          connection={connection}
          documents={[document]}
        />
      )}
      {isEditModalOpen && (
        <DocumentsForm queryKeys={queryKeys} existingDocument={document} closeModal={closeModal} />
      )}
      <IconButton icon={DotsVerticalIcon} ref={menuRef} onClick={toggleMenu} />
      {menu}
    </StyledCell>
  );
};

DropdownCell.propTypes = {
  value: PropTypes.string.isRequired,
  queryKeys: PropTypes.shape({
    detail: PropTypes.func.isRequired,
  }).isRequired,
  parentQueryKeys: PropTypes.shape({
    detail: PropTypes.func.isRequired,
  }),
  documentsForm: PropTypes.func.isRequired,
  connection: PropTypes.object,
  actionsDisabled: PropTypes.bool,
};

export default DropdownCell;

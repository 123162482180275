/* eslint-disable no-useless-escape */
const Types = {
  CLIENT_PROFILES: "client-profiles/",
  CLIENT_NOTES: clientId => `client-profiles/${clientId}/notes/`,
  CLIENT_DOCUMENTS: clientId => `client-profiles/${clientId}/documents/`,
  COMPANY: "companies/",
  COMMISSIONS: tradeId => `connections/${tradeId}/commission/`,
  CONNECTIONS: "connections/",
  DEAL_FILE_BUYSIDE_DOCUMENTS: tradeId => `connections/${tradeId}/buyside-documents/`,
  DEAL_FILE_SELLSIDE_DOCUMENTS: tradeId => `connections/${tradeId}/sellside-documents/`,
  DEAL_FILE_TRANSFER_DOCUMENTS: tradeId => `connections/${tradeId}/transfer-documents/`,
  DEAL_FILE_COMPLIANCE_DOCUMENTS: tradeId => `connections/${tradeId}/compliance-documents/`,
  EXECUTION_REPS: "execution-representatives/",
  EXPIRING_DOCUMENTS: "expiring-documents/",
  FUNDING_ROUNDS: companyId => `companies/${companyId}/funding-rounds/`,
  MARKET_OPERATORS: "market-operators/",
  NOTES: tradeId => `connections/${tradeId}/notes/`,
  ONBOARDING_DOCUMENTS: onboardingRequestId =>
    `onboarding-requests/${onboardingRequestId}/documents/`,
  ONBOARDING_LOOKUP: "onboarding-flow-lookup/",
  ONBOARDING_REQUEST: "onboarding-requests/",
  SOURCES: "sources/", // ZX Source - client profile source
  SPV: "spvs/",
  SPV_DOCUMENTS: spvId => `spvs/${spvId}/documents/`,
  SPV_CLASSES: "spv-classes/",
  SPV_CLASS_DOCUMENTS: spvClassId => `spv-classes/${spvClassId}/documents/`,
  SPV_INVESTMENTS: "spv-investments/",
  SPV_INVESTMENT_DOCUMENTS: spvInvestmentId => `spv-investments/${spvInvestmentId}/documents/`,
  SPV_INVITE: "spv-invites/",
  SPV_INVITE_DOCUMENTS: spvInviteId => `spv-invites/${spvInviteId}/documents/`,
  SPV_NOTES: spvId => `spvs/${spvId}/notes/`,
  SPV_CLASS_NOTES: spvClassId => `spv-classes/${spvClassId}/notes/`,
  STATUS_UPDATES: tradeId => `connections/${tradeId}/status-updates/`,
  TASKS: "tasks/",
};

const DataTypes = {
  CLIENTS: "SearchableSource", // Source fk for client profiles
  CLIENT_PROFILES: "SourceProfile",
  CLIENT_NOTES: "ClientNote",
  COMMISSIONS: "Commission",
  COMPANY: "Company",
  CONNECTIONS: "Connection",
  CONTACT: "ContactDetails",
  EXPIRABLE_DOCUMENT: "ExpirableDocument",
  EXECUTION_REP: "ExecutionUser",
  MARKET_OPERATORS: "Participant",
  NOTE: "ConnectionNote",
  ONBOARDING_LOOKUP: "OnboardingFlowType",
  ONBOARDING_REQUEST: "OnboardingRequest",
  SOURCES: "Source", // Connection Source
  SPV: "SPV",
  SPV_CLASS: "SPVClass",
  SPV_INVESTMENT: "SPVInvestment",
  SPV_INVITE: "SPVInvite",
  SPV_NOTE: "SPVNote",
  STATUS_UPDATE: "StatusUpdate",
  TASK: "ConnectionTask",
  TICKET: "ConnectedTicket",
};

const LIST = "list";
const DETAIL = "detail";

const matchUuid = "[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}";

const WHITELISTED_ROUTES_REGEX = [
  /^auth\/login\/$/,
  new RegExp(`^onboarding-requests\/${matchUuid}\/$`, "i"),
  new RegExp(`^onboarding-requests\/${matchUuid}\/.*\/$`, "i"),
  new RegExp(`^onboarding-flow-lookup\/${matchUuid}\/$`, "i"),
  new RegExp(`^spv-invites\/${matchUuid}\/$`, "i"),
  new RegExp(`^spv-invites\/${matchUuid}\/.*\/$`, "i"),
];

const whitelistedRoute = url => {
  return WHITELISTED_ROUTES_REGEX.some(regex => regex.test(url));
};

/**
 * These status codes match those that are defined by DRF constants as well as some
 * custom status codes of our own.  Our custom status code numbers should start at
 * x99 and decrement from there so there is little chance of overlap.
 *
 * http://www.django-rest-framework.org/api-guide/status-codes/
 */
const StatusCodes = {
  // 1xx Informational
  HTTP_100_CONTINUE: 100,
  HTTP_101_SWITCHING_PROTOCOLS: 101,

  // 2xx Successful
  HTTP_200_OK: 200,
  HTTP_201_CREATED: 201,
  HTTP_202_ACCEPTED: 202,
  HTTP_203_NON_AUTHORITATIVE_INFORMATION: 203,
  HTTP_204_NO_CONTENT: 204,
  HTTP_205_RESET_CONTENT: 205,
  HTTP_206_PARTIAL_CONTENT: 206,
  HTTP_207_MULTI_STATUS: 207,
  HTTP_299_MAINTENANCE: 299,

  // 3xx Redirection
  HTTP_300_MULTIPLE_CHOICES: 300,
  HTTP_301_MOVED_PERMANENTLY: 301,
  HTTP_302_FOUND: 302,
  HTTP_303_SEE_OTHER: 303,
  HTTP_304_NOT_MODIFIED: 304,
  HTTP_305_USE_PROXY: 305,
  HTTP_306_RESERVED: 306,
  HTTP_307_TEMPORARY_REDIRECT: 307,

  // 4xx Client Error
  HTTP_400_BAD_REQUEST: 400,
  HTTP_401_UNAUTHORIZED: 401,
  HTTP_402_PAYMENT_REQUIRED: 402,
  HTTP_403_FORBIDDEN: 403,
  HTTP_404_NOT_FOUND: 404,
  HTTP_405_METHOD_NOT_ALLOWED: 405,
  HTTP_406_NOT_ACCEPTABLE: 406,
  HTTP_407_PROXY_AUTHENTICATION_REQUIRED: 407,
  HTTP_408_REQUEST_TIMEOUT: 408,
  HTTP_409_CONFLICT: 409,
  HTTP_410_GONE: 410,
  HTTP_411_LENGTH_REQUIRED: 411,
  HTTP_412_PRECONDITION_FAILED: 412,
  HTTP_413_REQUEST_ENTITY_TOO_LARGE: 413,
  HTTP_414_REQUEST_URI_TOO_LONG: 414,
  HTTP_415_UNSUPPORTED_MEDIA_TYPE: 415,
  HTTP_416_REQUESTED_RANGE_NOT_SATISFIABLE: 416,
  HTTP_417_EXPECTATION_FAILED: 417,
  HTTP_422_UNPROCESSABLE_ENTITY: 422,
  HTTP_423_LOCKED: 423,
  HTTP_424_FAILED_DEPENDENCY: 424,
  HTTP_428_PRECONDITION_REQUIRED: 428,
  HTTP_429_TOO_MANY_REQUESTS: 429,
  HTTP_431_REQUEST_HEADER_FIELDS_TOO_LARGE: 431,
  HTTP_451_UNAVAILABLE_FOR_LEGAL_REASONS: 451,

  // 5xx Server Error
  HTTP_500_INTERNAL_SERVER_ERROR: 500,
  HTTP_501_NOT_IMPLEMENTED: 501,
  HTTP_502_BAD_GATEWAY: 502,
  HTTP_503_SERVICE_UNAVAILABLE: 503,
  HTTP_504_GATEWAY_TIMEOUT: 504,
  HTTP_505_HTTP_VERSION_NOT_SUPPORTED: 505,
  HTTP_507_INSUFFICIENT_STORAGE: 507,
  HTTP_511_NETWORK_AUTHENTICATION_REQUIRED: 511,
};

export { DataTypes, StatusCodes, Types, LIST, DETAIL, whitelistedRoute };

import PropTypes from "prop-types";
import styled from "styled-components";
import { useForm, useFormState } from "react-final-form";
import { InputRow, FinalFormField, Button, RefreshIcon } from "yuka";

import LoadingSpinner from "utils/LoadingSpinner";
import { Input } from "forms/FormComponents";
import MarketOperatorSelect from "forms/FormComponents/MarketOperatorSelect";

import { KYC_OPTIONS } from "./constants";

const StyledWrapper = styled(InputRow)`
  margin-bottom: 0;
`;

const StyledButton = styled(Button)`
  margin-left: auto;
`;

const StyledLoadingSpinner = styled.div`
  margin: auto 0 auto auto;
`;

const SPVInvestmentFilter = ({ isLoading }) => {
  const { reset } = useForm();
  const { dirty } = useFormState();

  return (
    <StyledWrapper>
      <Input name="search" placeholder="Search" width={200} />
      <FinalFormField
        type="checkbox-dropdown"
        name="kycStatus"
        placeholder="KYC Status"
        options={KYC_OPTIONS.map(value => ({ label: value, value }))}
        width={200}
      />
      <MarketOperatorSelect placeholder="Assigned ZX Rep" name="representative" width={200} />
      <StyledButton disabled={!dirty} leadingIcon={RefreshIcon} onClick={() => reset()}>
        Reset Filters
      </StyledButton>
      {isLoading && (
        <StyledLoadingSpinner>
          <LoadingSpinner showContainer={false} />
        </StyledLoadingSpinner>
      )}
    </StyledWrapper>
  );
};

SPVInvestmentFilter.propTypes = {
  typeOptions: PropTypes.arrayOf(PropTypes.string),
  isLoading: PropTypes.bool,
};

SPVInvestmentFilter.defaultProps = {
  isLoading: false,
};

export default SPVInvestmentFilter;

import { useContext } from "react";
import PropTypes from "prop-types";

import { getRelatedObj } from "api";
import RelationshipsContext from "utils/RelationshipsContext";

const SPVInviteMarketOperatorCell = ({ value: spvInvite }) => {
  const { spvInviteRelationships } = useContext(RelationshipsContext);
  const marketOperator = getRelatedObj(spvInvite, spvInviteRelationships);
  return marketOperator?.name;
};

SPVInviteMarketOperatorCell.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string),
};

const SPVInvestmentMarketOperatorCell = ({ value: spvInvestment }) => {
  const { spvInvestmentRelationships } = useContext(RelationshipsContext);
  const marketOperators = spvInvestment.zxRepresentatives
    ?.map(rep => getRelatedObj(rep, spvInvestmentRelationships)?.name)
    .filter(x => x);
  return marketOperators.join(", ");
};

SPVInvestmentMarketOperatorCell.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string),
};

export { SPVInviteMarketOperatorCell, SPVInvestmentMarketOperatorCell };

import { Form } from "react-final-form";
import { useCallback, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { FormModal } from "yuka";

import { useWrite, QUERY_KEYS, DataTypes } from "api";
import { queryClient } from "api/queryClient";
import LoadingSpinner from "utils/LoadingSpinner";
import { mpTrack } from "utils/mixpanel";
import { LinkDocumentsTable } from "templates/DocumentsTable";

const StyledContainer = styled.div`
  width: 1000px;
`;

const LinkSPVInvestorDocsForm = ({ spvInvestment, onClose }) => {
  const { onSubmit } = useWrite(
    QUERY_KEYS.SPV_INVESTMENTS.detail(spvInvestment.apiId, ["import-documents"])
  );

  const [selectedDocuments, setSelectedDocuments] = useState([]);

  const wrappedOnSubmit = useCallback(
    (_, ...params) => {
      const documentsToLink = selectedDocuments.map(document => ({
        apiId: document.apiId,
        apiType: DataTypes.EXPIRABLE_DOCUMENT,
      }));
      const requestData = {
        apiId: spvInvestment.apiId,
        apiType: spvInvestment.apiType,
        documents: documentsToLink,
      };
      onSubmit(requestData, ...params).then(() => {
        onClose();
        mpTrack("linked client documents to spv investment", {
          "trade id": spvInvestment.investmentId,
          "participant name": spvInvestment.name,
          "number of documents": documentsToLink.length,
        });
        queryClient.invalidateQueries(
          QUERY_KEYS.SPV_INVESTMENT_DOCUMENTS.list(spvInvestment.apiId)
        );
      });
    },
    [spvInvestment, onClose, onSubmit, selectedDocuments]
  );

  return (
    <Form onSubmit={wrappedOnSubmit}>
      {({ submitting, handleSubmit }) => (
        <FormModal
          onClose={onClose}
          onSubmit={handleSubmit}
          submitText="Confirm Import"
          title="Link Documents"
          requireDirtyForSubmit={false}
        >
          {submitting && <LoadingSpinner />}
          <StyledContainer>
            <LinkDocumentsTable
              setSelectedDocuments={setSelectedDocuments}
              pk={spvInvestment.source}
            />
          </StyledContainer>
        </FormModal>
      )}
    </Form>
  );
};

LinkSPVInvestorDocsForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  spvInvestment: PropTypes.shape({
    apiId: PropTypes.number,
    apiType: PropTypes.string,
    investmentId: PropTypes.string,
    source: PropTypes.string,
    name: PropTypes.string,
  }),
};

export default LinkSPVInvestorDocsForm;

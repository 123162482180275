import { useEffect, useState } from "react";
import { useWrite, DataTypes } from "api";
import useFileDragAndDrop from "utils/hooks/form/useFileDragAndDrop";
import { useDispatch, ACTIONS } from "utils/StateProvider";

const useDocumentDragUpload = (queryKeys, pk) => {
  const dispatch = useDispatch();
  const { onSubmit } = useWrite(queryKeys.list(pk), {
    silent: true,
  });
  const [totalFiles, setTotalFiles] = useState(0);
  const [completedFiles, setCompletedFiles] = useState(0);

  const handleUpload = files => {
    setTotalFiles(files.length);
    setCompletedFiles(0);
    // Spread files to get an array rather than FileList
    return Promise.all(
      [...files].map(file =>
        onSubmit({ apiType: DataTypes.EXPIRABLE_DOCUMENT, file }).then(() => {
          setCompletedFiles(completed => completed + 1);
        })
      )
    );
  };

  useEffect(() => {
    if (completedFiles === totalFiles && totalFiles > 0) {
      dispatch({
        type: ACTIONS.addToast,
        message: "Upload Complete",
      });
      setTotalFiles(0);
    }
  }, [completedFiles, totalFiles, setTotalFiles, dispatch]);

  return useFileDragAndDrop({
    multiple: true,
    onChange: handleUpload,
  });
};

export default useDocumentDragUpload;

import React, { useState, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  Button,
  Card,
  CardStyles,
  ComponentGroup,
  LinkTypes,
  PlusIcon,
  MailIcon,
  Table,
  TabList,
  useCurrentTab,
} from "yuka";

import { QUERY_KEYS, useFetch, useWrite, DataTypes, getRelatedId } from "api";
import { queryClient } from "api/queryClient";
import RelationshipsContext from "utils/RelationshipsContext";
import AddInvestmentForm from "forms/AddInvestmentForm";
import SPVInvestmentFilter, { useInvestmentFilterConfig } from "forms/SPVInvestmentFilter";
import Filter from "forms/Filter";
import { SPV_INVESTMENT_FILTERS } from "forms/filterNames";

import { investmentColumns, inviteSentColumns } from "./columns";

const StyledCard = styled(Card)`
  width: 100%;
`;

const PaddedWrapper = styled.div`
  padding: 0 16px;
`;

const INVESTORS_QUERY_PARAM = "investors";
const SUBSCRIBERS = "Subscribers";
const INVITE_SENT = "Invite Sent";

/**
 * Renders a card for the investors in an spv class
 *
 * @param {object} prop
 * @returns {JSX.Element}
 */
const InvestorsCard = ({ spvClass }) => {
  const currentTab = useCurrentTab(INVESTORS_QUERY_PARAM);
  const navigate = useNavigate();
  const [formOpen, setFormOpen] = useState(false);
  const [selectedInvestments, setSelectedInvestments] = useState([]);

  const { queryParams: spvInvestmentFilterParams, filterProps } = useInvestmentFilterConfig();

  // Get SPV Invites
  const spvInviteQueryParams = {
    "filter[spv_class]": spvClass.apiId,
    include: "marketOperator",
  };
  const {
    isError: isErrorSPVInvites,
    isLoading: isLoadingSPVInvites,
    data: spvInvites,
    relationships: spvInviteRelationships,
  } = useFetch(QUERY_KEYS.SPV_INVITE.list({ ...spvInviteQueryParams }));

  // Get Onboard requests
  const spvInvestmentQueryParams = {
    "filter[spv_class]": spvClass.apiId,
    include: "zxRepresentatives,onboardingRequest",
    ...spvInvestmentFilterParams,
  };
  const {
    isError: isErrorSPVInvestments,
    isLoading: isLoadingSPVInvestments,
    isFetching: isFetchingSPVInvestments,
    data: spvInvestments,
    relationships: spvInvestmentRelationships,
  } = useFetch(QUERY_KEYS.SPV_INVESTMENTS.list({ ...spvInvestmentQueryParams }));

  const tabArray = useMemo(
    () => [
      {
        label: SUBSCRIBERS,
        count: spvInvestments?.length,
      },
      { label: INVITE_SENT, count: spvInvites?.length },
    ],
    [spvInvestments, spvInvites]
  );

  const navigateToProfile = useCallback(
    ({ row: { original: spvInvestment } }) => {
      navigate(`/spvs/class/investment/${spvInvestment.apiId}/`);
    },
    [navigate]
  );

  const onRowSelectStateChange = useCallback(
    data => {
      let newSelectedInvestments = [];
      Object.keys(data).map(rowNumber => {
        newSelectedInvestments.push(spvInvestments[rowNumber].apiId);
      });
      // Primarily for preventing infinite hangs in tests
      if (!newSelectedInvestments.length && !selectedInvestments.length) {
        return;
      }
      setSelectedInvestments(newSelectedInvestments);
      return;
    },
    [setSelectedInvestments, selectedInvestments, spvInvestments]
  );

  const { onSubmit: requestKycOnSubmit } = useWrite(
    QUERY_KEYS.SPV.detail(getRelatedId(spvClass.spv), ["kyc-investors"]),
    {
      message: "Requested investor compliance approval",
    }
  );

  const requestKyc = () =>
    requestKycOnSubmit({
      apiId: getRelatedId(spvClass.spv),
      apiType: DataTypes.SPV,
      spvInvestments: selectedInvestments.map(apiId => ({
        apiId,
        apiType: DataTypes.SPV_INVESTMENT,
      })),
    }).then(() => {
      queryClient.invalidateQueries(
        QUERY_KEYS.SPV_INVESTMENTS.list({ ...spvInvestmentQueryParams })
      );
    });

  // Onboard investors
  const actions = (
    <>
      {selectedInvestments.length > 0 && (
        <Button onClick={requestKyc}>Request Compliance Review</Button>
      )}
      <Button leadingIcon={PlusIcon} onClick={() => setFormOpen(true)}>
        Add Subscribers
      </Button>
      <Button
        leadingIcon={MailIcon}
        url={`/spvs/class/onboarding/${spvClass.apiId}`}
        linkType={LinkTypes.LOCAL_LINK}
      >
        Invite Prospects
      </Button>
    </>
  );

  if (isErrorSPVInvites) {
    return <div>An error has occurred loading Invites Sent</div>;
  } else if (isErrorSPVInvestments) {
    return <div>An error has occurred loading Completed and Pending Requests</div>;
  }

  return (
    <StyledCard title="Investors" headerTrailingContent={actions} cardStyle={CardStyles.SECTIONED}>
      <ComponentGroup>
        <PaddedWrapper>
          <TabList tabs={tabArray} paramName={INVESTORS_QUERY_PARAM} />
        </PaddedWrapper>
        <RelationshipsContext.Provider
          value={{ spvInviteRelationships, spvInvestmentRelationships }}
        >
          {currentTab === SUBSCRIBERS && (
            <>
              <PaddedWrapper>
                <Filter
                  name={SPV_INVESTMENT_FILTERS}
                  filterComponent={SPVInvestmentFilter}
                  isLoading={isFetchingSPVInvestments}
                  {...filterProps}
                />
              </PaddedWrapper>
              <Table
                emptyTablePlaceholder="No investor subscriptions"
                isLoading={isLoadingSPVInvestments}
                data={spvInvestments || []}
                columns={investmentColumns}
                navigateToProfile={navigateToProfile}
                onRowSelectStateChange={onRowSelectStateChange}
              />
            </>
          )}
          {currentTab === INVITE_SENT && (
            <Table
              usePercentageColumnWidths
              emptyTablePlaceholder="No Invites Sent"
              isLoading={isLoadingSPVInvites}
              data={spvInvites || []}
              columns={inviteSentColumns}
            />
          )}
        </RelationshipsContext.Provider>
      </ComponentGroup>
      {formOpen && <AddInvestmentForm spvClass={spvClass} onClose={() => setFormOpen(false)} />}
    </StyledCard>
  );
};

InvestorsCard.propTypes = {
  spvClass: PropTypes.shape({
    apiId: PropTypes.string.isRequired,
    spv: PropTypes.array.isRequired,
  }).isRequired,
};

export default InvestorsCard;

import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Button, Card, CardStyles, Table } from "yuka";

import { QUERY_KEYS, useFetch, useWrite, DataTypes } from "api";
import { queryClient } from "api/queryClient";
import RelationshipsContext from "utils/RelationshipsContext";
import SPVInvestmentFilter, { useInvestmentFilterConfig } from "forms/SPVInvestmentFilter";
import Filter from "forms/Filter";
import { SPV_INVESTMENT_FILTERS } from "forms/filterNames";

import { investmentColumnsWithClass } from "../SPVClassProfileView/InvestorsCard/columns";

const StyledCard = styled(Card)`
  width: 100%;
`;

const PaddedWrapper = styled.div`
  padding: 0 16px;
`;

/**
 * Renders a card for the investors in an spv class
 *
 * @param {object} prop
 * @returns {JSX.Element}
 */
const InvestorsCard = ({ spv }) => {
  const navigate = useNavigate();
  const [selectedInvestments, setSelectedInvestments] = useState([]);

  const { queryParams: filterParams, filterProps } = useInvestmentFilterConfig();

  // Get Onboard requests
  const queryParams = {
    "filter[spv]": spv.apiId,
    include: "zxRepresentatives,onboardingRequest",
    ...filterParams,
  };
  const {
    isError,
    isLoading,
    isFetching,
    data: spvInvestments,
    relationships: spvInvestmentRelationships,
  } = useFetch(QUERY_KEYS.SPV_INVESTMENTS.list({ ...queryParams }));

  const navigateToProfile = useCallback(
    ({ row: { original: spvInvestment } }) => {
      navigate(`/spvs/class/investment/${spvInvestment.apiId}/`);
    },
    [navigate]
  );

  const onRowSelectStateChange = useCallback(
    data => {
      let newSelectedInvestments = [];
      Object.keys(data).map(rowNumber => {
        newSelectedInvestments.push(spvInvestments[rowNumber].apiId);
      });
      // Primarily for preventing infinite hangs in tests
      if (!newSelectedInvestments.length && !selectedInvestments.length) {
        return;
      }
      setSelectedInvestments(newSelectedInvestments);
      return;
    },
    [setSelectedInvestments, selectedInvestments, spvInvestments]
  );

  const { onSubmit: requestKycOnSubmit } = useWrite(
    QUERY_KEYS.SPV.detail(spv.apiId, ["kyc-investors"]),
    {
      message: "Requested investor compliance approval",
    }
  );

  const requestKyc = () =>
    requestKycOnSubmit({
      apiId: spv.apiId,
      apiType: DataTypes.SPV,
      spvInvestments: selectedInvestments.map(apiId => ({
        apiId,
        apiType: DataTypes.SPV_INVESTMENT,
      })),
    }).then(() => {
      queryClient.invalidateQueries(QUERY_KEYS.SPV_INVESTMENTS.list({ ...queryParams }));
    });

  if (isError) {
    return <div>An error has occurred loading Completed and Pending Requests</div>;
  }

  const actions = (
    <>
      {selectedInvestments.length > 0 && (
        <Button onClick={requestKyc}>Request Compliance Review</Button>
      )}
    </>
  );

  return (
    <StyledCard
      title="Subscribers"
      cardStyle={CardStyles.SECTIONED}
      headerTrailingContent={actions}
    >
      <PaddedWrapper>
        <Filter
          name={SPV_INVESTMENT_FILTERS}
          filterComponent={SPVInvestmentFilter}
          isLoading={isFetching}
          {...filterProps}
        />
      </PaddedWrapper>
      <RelationshipsContext.Provider value={{ spvInvestmentRelationships }}>
        <Table
          emptyTablePlaceholder="No investor subscriptions"
          isLoading={isLoading}
          data={spvInvestments || []}
          columns={investmentColumnsWithClass}
          navigateToProfile={navigateToProfile}
          onRowSelectStateChange={onRowSelectStateChange}
        />
      </RelationshipsContext.Provider>
    </StyledCard>
  );
};

InvestorsCard.propTypes = {
  spv: PropTypes.shape({
    apiId: PropTypes.string.isRequired,
  }).isRequired,
};

export default InvestorsCard;

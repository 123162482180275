import { BrowserRouter, Route, Routes, Navigate, Outlet } from "react-router-dom";
import styled from "styled-components";
import { PageSection, YukaThemeProvider, FontColors } from "yuka";

import { QueryClientProvider } from "api";
import { Unauthorized, NotFound } from "templates/ErrorPages";
import StateProvider from "utils/StateProvider";
import ToastList from "templates/ToastList";

import Navigation from "./Navigation";
import TaskView from "./TaskView";
import TemplateView from "./TemplateView";
import LiveConnectionsView from "./LiveConnectionsView";
import SettledConnectionsView from "./SettledConnectionsView";
import ClosedConnectionsView from "./ClosedConnectionsView";
import DisconnectedConnectionsView from "./DisconnectedConnectionsView";
import AllConnectionsView from "./AllConnectionsView";
import ClientTableView from "./ClientTableView";
import ClientProfileView from "./ClientProfileView";
import RequestClientOnboardingView from "./RequestClientOnboardingView";
import SPVTableView from "./SPVTableView";
import SPVProfileView from "./SPVProfileView";
import SPVClassProfileView from "./SPVClassProfileView";
import SPVInvestmentProfileView from "./SPVInvestmentProfileView";
import RequestSPVOnboardingView from "./RequestSPVOnboardingView";
import defaultTheme from "../xeYuka/defaultTheme";

const Layout = styled(PageSection)`
  ${FontColors.theme80};
  flex-direction: row;
  height: 100vh;
  width: 100%;
`;

const StyledMain = styled.main`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 16px;
  overflow-y: auto;
`;

const NavLayout = () => (
  <Layout>
    <Navigation />
    <StyledMain>
      <Outlet />
    </StyledMain>
  </Layout>
);

const ConnectionRoutes = () => (
  <Routes>
    <Route index element={<Navigate to="live/" replace />} />
    <Route path="all//*" element={<AllConnectionsView />} />
    <Route path="live//*" element={<LiveConnectionsView />} />
    <Route path="settled//*" element={<SettledConnectionsView />} />
    <Route path="closed//*" element={<ClosedConnectionsView />} />
    <Route path="disconnected//*" element={<DisconnectedConnectionsView />} />
  </Routes>
);

// Split this out so relative links work as intended
const ClientRoutes = () => (
  <Routes>
    <Route index element={<ClientTableView />} />
    <Route path=":id/" element={<ClientProfileView />} />
  </Routes>
);

const SPVRoutes = () => (
  <Routes>
    <Route index element={<SPVTableView />} />
    <Route path=":id/" element={<SPVProfileView />} />
    <Route path="class/:id/" element={<SPVClassProfileView />} />
    <Route path="class/investment/:id/" element={<SPVInvestmentProfileView />} />
  </Routes>
);

const ExecutionApp = () => {
  let userMetadata = JSON.parse(localStorage.getItem("userMetadata"));
  return (
    <YukaThemeProvider theme={defaultTheme}>
      <QueryClientProvider>
        <StateProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/clients/onboarding/:id" element={<RequestClientOnboardingView />} />
              <Route path="/spvs/class/onboarding/:id" element={<RequestSPVOnboardingView />} />
              <Route path="/" element={<NavLayout />}>
                <Route index element={<Navigate to="connections/" replace />} />
                {userMetadata?.canViewAllConnections && (
                  <Route path="tasks/" element={<TaskView />} />
                )}
                <Route path="templates/" element={<TemplateView />} />
                <Route path="connections//*" element={<ConnectionRoutes />} />
                <Route path="clients//*" element={<ClientRoutes />} />
                {userMetadata?.canViewAnySpv && <Route path="spvs//*" element={<SPVRoutes />} />}
                <Route path="401/" element={<Unauthorized />} />
                <Route path="*" element={<NotFound />} />
              </Route>
            </Routes>
          </BrowserRouter>
          <ToastList />
        </StateProvider>
      </QueryClientProvider>
    </YukaThemeProvider>
  );
};

export default ExecutionApp;

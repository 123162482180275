import {
  SPV_TYPE_MASTER,
  SPV_TYPE_SERIES,
  SPV_TYPE_STANDALONE,
  SPV_TYPE_CLASS,
} from "utils/constants";

const INVESTORS = "Investors";
const DOCUMENTS = "Documents";
const SERIES = "Series";
const CLASSES = "Classes";
const NOTES = "Notes";
const SETTINGS = "Settings";

const SPV_MASTER_TABS = [SERIES, DOCUMENTS, NOTES, SETTINGS];
const SPV_STANDALONE_TABS = [INVESTORS, CLASSES, DOCUMENTS, NOTES, SETTINGS];
const SPV_SERIES_TABS = [INVESTORS, CLASSES, DOCUMENTS, NOTES, SETTINGS];

const ALL_TABS = [SERIES, INVESTORS, CLASSES, DOCUMENTS, NOTES, SETTINGS];

const getTabsForProfile = spvType => {
  switch (spvType) {
    case SPV_TYPE_MASTER:
      return SPV_MASTER_TABS;
    case SPV_TYPE_SERIES:
      return SPV_SERIES_TABS;
    case SPV_TYPE_STANDALONE:
      return SPV_STANDALONE_TABS;
    default: // should never reach the default
      return SPV_SERIES_TABS;
  }
};

const getInitialValues = spv => {
  switch (spv.spvType) {
    case SPV_TYPE_MASTER:
      return { parentName: spv.name, parent: spv.apiId };
    case SPV_TYPE_SERIES:
      return {
        parentType: SPV_TYPE_SERIES,
        masterName: spv.parentName,
        series: spv.apiId,
        spvName: spv.name,
      };
    case SPV_TYPE_STANDALONE:
      return {
        parentType: SPV_TYPE_STANDALONE,
        standalone: spv.apiId,
        spvName: spv.name,
      };
    default: // should never reach the default
      return {};
  }
};

export {
  SPV_TYPE_MASTER,
  SPV_TYPE_SERIES,
  SPV_TYPE_STANDALONE,
  SPV_TYPE_CLASS,
  DOCUMENTS,
  SERIES,
  CLASSES,
  NOTES,
  INVESTORS,
  SETTINGS,
  ALL_TABS,
  getTabsForProfile,
  getInitialValues,
};

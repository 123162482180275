import { ButtonStyles, IconButtonStyles } from "yuka";
import { css } from "styled-components";

const KEY_ACTION_BUTTON_CONTAINER = css`
  border-radius: 12px;
  padding: 0 12px;
  height: 44px;
`;

const KEY_ACTION_ICON_BUTTON_CONTAINER = css`
  border-radius: 12px;
  padding: 11px;
  width: 44px;
  height: 44px;
`;

export default {
  button: {
    [ButtonStyles.RAISED]: {
      container: KEY_ACTION_BUTTON_CONTAINER,
    },
  },
  iconButton: {
    [IconButtonStyles.RAISED]: {
      container: KEY_ACTION_ICON_BUTTON_CONTAINER,
    },
  },
};

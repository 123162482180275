import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-final-form";

import { FinalFormField, FormModal } from "yuka";

import { writeQuery, DataTypes, getRelatedId, QUERY_KEYS, useFetch } from "api";
import { queryClient } from "api/queryClient";
import { InputRow } from "forms/FormComponents";
import { useDispatch } from "utils/StateProvider";

const LinkDealFileDocumentForm = ({ closeModal, connection, documents }) => {
  const dispatch = useDispatch();
  const { data: sourceProfileOptions } = useFetch(
    QUERY_KEYS.CLIENT_PROFILES.list({ "filter[connection]": connection.apiId })
  );
  const wrappedOnSubmit = values => {
    const cleanedValues = {
      apiId: values.sourceId,
      apiType: DataTypes.CLIENT_PROFILES,
      documents: documents,
    };
    return writeQuery(
      QUERY_KEYS.CLIENT_PROFILES.detail(values.sourceId, ["link_documents"]),
      cleanedValues,
      dispatch
    )
      .then(() => {
        queryClient.invalidateQueries(QUERY_KEYS.CONNECTIONS.detail(connection.tradeId));
        closeModal();
      })
      .catch(() => {
        closeModal();
      });
  };
  return (
    <Form onSubmit={wrappedOnSubmit}>
      {({ handleSubmit }) => (
        <FormModal
          onClose={closeModal}
          onSubmit={handleSubmit}
          submitText="Link"
          title="Link Document to profile"
        >
          <InputRow>
            <FinalFormField
              type="select"
              label="Client Profile"
              placeholder="Enter name"
              options={
                sourceProfileOptions?.map(sourceProfile => ({
                  label: sourceProfile.name,
                  value: getRelatedId(sourceProfile.source),
                })) || []
              }
              name="sourceId"
              popup
            />
          </InputRow>
        </FormModal>
      )}
    </Form>
  );
};

LinkDealFileDocumentForm.propTypes = {
  closeModal: PropTypes.func.isRequired,
  connection: PropTypes.shape({
    apiId: PropTypes.string.isRequired,
    apiType: PropTypes.string.isRequired,
    tradeId: PropTypes.string.isRequired,
  }),
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      apiId: PropTypes.string.isRequired,
    })
  ),
};

export default LinkDealFileDocumentForm;

import React, { useCallback, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Card, CardStyles, Table } from "yuka";

import { useInfiniteFetch, QUERY_KEYS, getRelatedId } from "api";
import LoadingSpinner from "utils/LoadingSpinner";
import { useMpSource, mpTrack } from "utils/mixpanel";
import useTableSort from "utils/tables/useTableSort";
import { ACTIONS, GlobalContext } from "utils/StateProvider";
import RelationshipsContext from "utils/RelationshipsContext";

import { columns, TABLE_NAME } from "./columns";

const StyledCard = styled(Card)`
  height: fit-content;
  width: 100%;
`;

const StyledTitle = styled.div`
  display: flex;
`;

const ConnectionsTab = ({ source }) => {
  const {
    state: { tableSort },
    dispatch,
  } = useContext(GlobalContext);

  const onSort = useTableSort(ACTIONS, dispatch, TABLE_NAME);
  const tableSortState = useMemo(() => {
    return tableSort[TABLE_NAME] || [{ id: "tradeId", desc: false, hideArrow: true }];
  }, [tableSort]);

  const tableSortQueryParam = useMemo(() => {
    if (tableSortState?.length) {
      return tableSortState[0].desc ? `-${tableSortState[0].id}` : tableSortState[0].id;
    }
    return undefined;
  }, [tableSortState]);

  const mpSource = useMpSource();
  const navigate = useNavigate();

  const clickTradeId = ({ value: tradeId }) =>
    navigator.clipboard
      .writeText(tradeId)
      .then(() => {
        dispatch({ type: ACTIONS.addToast, message: `Copied "${tradeId}" to clipboard.` });
      })
      .catch(() => {
        dispatch({ type: ACTIONS.addToast, message: "Failed to copy." });
      });

  const navigateToConnectionProfile = useCallback(
    ({ row: { original: originalData } }) => {
      mpTrack("click into connection profile", {
        source: `${mpSource} - ${TABLE_NAME}`,
        tradeId: originalData.tradeId,
        company: getRelatedId(originalData.company),
      });
      navigate(`/connections/all/${originalData.tradeId}/`);
    },
    [mpSource, navigate]
  );

  const queryParams = {
    sort: tableSortQueryParam,
    "page[size]": 50,
    "filter[source]": source.searchId,
  };

  const { isFetching, isLoading, data, fetchNextPage, isFetchingNextPage, relationships } =
    useInfiniteFetch(QUERY_KEYS.CONNECTIONS.list({ ...queryParams }));

  const title = (
    <StyledTitle>
      <span>Connections</span>
      {isFetching && <LoadingSpinner />}
    </StyledTitle>
  );

  return (
    <StyledCard title={title} cardStyle={CardStyles.TITLE}>
      <RelationshipsContext.Provider value={relationships}>
        <Table
          manualSortBy
          sortState={tableSortState}
          onSort={onSort}
          paginationFunc={fetchNextPage}
          isPaginationLoading={isFetchingNextPage}
          isLoading={isLoading}
          data={data}
          columns={columns}
          navigateToConnectionProfile={navigateToConnectionProfile}
          clickTradeId={clickTradeId}
        />
      </RelationshipsContext.Provider>
    </StyledCard>
  );
};

ConnectionsTab.propTypes = {
  source: PropTypes.shape({
    name: PropTypes.string,
    category: PropTypes.string,
    searchId: PropTypes.number,
  }).isRequired,
};

export default ConnectionsTab;
export { RelationshipsContext };

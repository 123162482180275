import PropTypes from "prop-types";
import { useContext } from "react";
import { ROLE_CLIENT } from "utils/constants";
import { getRelatedObj } from "api";
import RelationshipsContext from "utils/RelationshipsContext";

const ClientCell = ({ value: connectedTicketId }) => {
  const relationships = useContext(RelationshipsContext);
  const sources = getRelatedObj(connectedTicketId, relationships).sources;

  if (sources) {
    const clientSourceObjects = sources
      .map(source => getRelatedObj(source, relationships))
      .filter(source => source.role === ROLE_CLIENT);
    if (clientSourceObjects.length > 0) {
      if (clientSourceObjects.length > 1) {
        let clientNames = [];
        for (let i = 0; i < clientSourceObjects.length; i++) {
          clientNames.push(
            clientSourceObjects[i].individualName || clientSourceObjects[i].entityName
          );
        }
        return clientNames.join(", ");
      }
      const broker = clientSourceObjects[0];
      return broker.individualName || broker.entityName;
    }
  }
  return "";
};

ClientCell.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string),
};

export default ClientCell;

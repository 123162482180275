import React, { useState } from "react";
import PropTypes from "prop-types";

import { IconButton, UploadIcon } from "yuka";

/**
 * Allows users to upload a new version of the document
 *
 * @param {object} prop
 * @returns {JSX.Element}
 */

const UploadCell = ({
  value: document,
  documentsForm: DocumentsForm,
  actionsDisabled,
  queryKeys,
}) => {
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [allowUpload, setAllowUpload] = useState(false);
  const uploadAction = () => {
    setAllowUpload(true);
    setEditModalOpen(true);
  };
  const closeModal = () => {
    setAllowUpload(false);
    setEditModalOpen(false);
  };

  return (
    <div>
      {isEditModalOpen && (
        <DocumentsForm
          queryKeys={queryKeys}
          existingDocument={document}
          allowUpload={allowUpload}
          closeModal={closeModal}
        />
      )}
      <IconButton
        onClick={uploadAction}
        icon={UploadIcon}
        disabled={actionsDisabled || document.isFromParent}
      />
    </div>
  );
};

UploadCell.propTypes = {
  value: PropTypes.string.isRequired,
  documentsForm: PropTypes.func.isRequired,
  actionsDisabled: PropTypes.bool,
  queryKeys: PropTypes.objectOf(PropTypes.func).isRequired,
};

export default UploadCell;

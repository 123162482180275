import {
  DEAL_FILE_STATUS_INFORMATION_REQUESTED,
  PHASE_INVOICING,
  PHASE_DEAL_FILE_REVIEW,
  DEAL_FILE_STATUS_INFORMATION_UPDATED,
  DEAL_FILE_STATUS_NEEDS_REVIEW,
  SPV,
  INTERNAL_SPV,
} from "utils/constants";

import ConnectionComplianceStatusCard from "./ConnectionComplianceStatusCard";
import ConnectionPhaseBadge from "./ConnectionPhaseBadge";
import DealFileStatusBadge from "./DealFileStatusBadge";

/**
 * Deal file is frozen once we are past deal file review. This prevents removing or editing
 * existing deal file documents, but upload is still permitted for some edge cases
 */
const canDownloadInvoice = connection =>
  [PHASE_INVOICING, PHASE_DEAL_FILE_REVIEW].includes(connection.phase);

const isPendingReview = connection => {
  return [DEAL_FILE_STATUS_INFORMATION_UPDATED, DEAL_FILE_STATUS_NEEDS_REVIEW].includes(
    connection.dealFileStatus
  );
};

const isSpvTrade = connection =>
  connection.tradeType === SPV || connection.tradeType === INTERNAL_SPV;

const getReviewRequestedStatus = connection => {
  if (connection.dealFileStatus === DEAL_FILE_STATUS_INFORMATION_REQUESTED) {
    return DEAL_FILE_STATUS_INFORMATION_UPDATED;
  }
  return DEAL_FILE_STATUS_NEEDS_REVIEW;
};

export {
  ConnectionPhaseBadge,
  ConnectionComplianceStatusCard,
  DealFileStatusBadge,
  canDownloadInvoice,
  isPendingReview,
  isSpvTrade,
  getReviewRequestedStatus,
};

import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import { IconButton, PinFilledIcon, PinIcon } from "yuka";

import { useWrite, DataTypes } from "api";

/**
 * Renders a pin icon for the documents table. Clicking the icon pins the document
 *
 * @param {object} prop
 * @returns {JSX.Element}
 */
const PinCell = ({ value: document, queryKeys, actionsDisabled }) => {
  const { id } = useParams();
  const { onSubmit } = useWrite(queryKeys.detail(id, document.apiId), {
    silent: true,
  });

  const handlePin = () => {
    const values = {
      isPinned: !document.isPinned,
      apiType: DataTypes.EXPIRABLE_DOCUMENT,
      apiId: document.apiId,
    };
    return onSubmit(values);
  };

  // PinFilledIcon ideally uses blue400
  return (
    <IconButton
      icon={document.isPinned ? PinFilledIcon : PinIcon}
      disabled={actionsDisabled || document.isFromParent}
      onClick={handlePin}
    />
  );
};

PinCell.propTypes = {
  value: PropTypes.string.isRequired,
  queryKeys: PropTypes.shape({
    detail: PropTypes.func.isRequired,
  }).isRequired,
  actionsDisabled: PropTypes.bool,
};

export default PinCell;

import React from "react";
import PropTypes from "prop-types";
import { Fonts, HyperLink, LinkStyles, LinkTypes } from "yuka";

import { STATUS_ARCHIVED } from "utils/constants";

/**
 * Renders the name of the file which the user can click to open the file in a new tab
 *
 * @param {object} prop
 * @returns {JSX.Element}
 */
const PreviewCell = ({ value: name, row: { original: document } }) => {
  const link = (
    <HyperLink url={document.file} linkStyle={LinkStyles.UNSTYLED} linkType={LinkTypes.DOWNLOAD}>
      {name}
    </HyperLink>
  );

  if (document.status !== STATUS_ARCHIVED) {
    return link;
  }
  return <Fonts.Body1theme30>{link}</Fonts.Body1theme30>;
};

PreviewCell.propTypes = {
  value: PropTypes.string.isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      file: PropTypes.string.isRequired,
      status: PropTypes.string,
    }).isRequired,
  }),
};

export default PreviewCell;

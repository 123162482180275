import React from "react";
import PropTypes from "prop-types";

import { IconButton, ListItem, ListStyles, XCloseIcon } from "yuka";

const DocumentListItem = ({ document, onRemove, spvClass }) => {
  let subText = "";
  if (document.isFromMaster) {
    subText = `From ${spvClass?.masterName}`;
  } else if (document.isFromParent) {
    subText = spvClass?.masterName
      ? `From ${spvClass.masterName} / ${spvClass.spvName}`
      : `From ${spvClass?.spvName}`;
  } else {
    subText = spvClass?.masterName
      ? `From ${spvClass.masterName} / ${spvClass.spvName} / ${spvClass.name}`
      : `From ${spvClass?.spvName} / ${spvClass?.name}`;
  }

  return (
    <ListItem
      listStyle={ListStyles.TWO_LINE}
      text={document.name}
      trailingContent={<IconButton onClick={() => onRemove(document)} icon={XCloseIcon} />}
      subtext={subText}
    />
  );
};

DocumentListItem.propTypes = {
  document: PropTypes.shape({
    apiId: PropTypes.string,
    isFromMaster: PropTypes.bool,
    isFromParent: PropTypes.bool,
    name: PropTypes.string,
  }).isRequired,
  onRemove: PropTypes.func.isRequired,
  spvClass: PropTypes.shape({
    name: PropTypes.string,
    spvName: PropTypes.string,
    masterName: PropTypes.string,
  }),
};

export default DocumentListItem;

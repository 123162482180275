/**
 * Contains a list of all names of filters used for `StateProvider` in order to hook into the global state.
 *
 * Allows components to modify behaviour based on filter values where the filter does not appear in the same
 * form context as the component.
 */

const CONNECTIONS_FILTERS = "CONNECTIONS_FILTERS";
const CLIENT_PROFILE_FILTERS = "CLIENT_PROFILE_FILTERS";
const DEAL_FILE_FILTERS = "DEAL_FILE_FILTERS";
const SPV_TABLE_FILTERS = "SPV_TABLE_FILTERS";
const SPV_CLASS_TABLE_FILTERS = "SPV_CLASS_TABLE_FILTERS";
const SPV_INVESTMENT_FILTERS = "SPV_INVESTMENT_FILTERS";

export {
  CONNECTIONS_FILTERS,
  CLIENT_PROFILE_FILTERS,
  DEAL_FILE_FILTERS,
  SPV_TABLE_FILTERS,
  SPV_CLASS_TABLE_FILTERS,
  SPV_INVESTMENT_FILTERS,
};

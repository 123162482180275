import styled from "styled-components";
import { useState } from "react";
import PropTypes from "prop-types";
import { Card, CardStyles, ActionChip, EyeIcon, EyeOffIcon, UploadIcon } from "yuka";
import { SPVDocumentsForm } from "forms/DocumentsForm";
import { SPVDocumentsTable } from "templates/DocumentsTable";

const StyledActions = styled.div`
  padding: 0 16px;
`;

const StyledCard = styled(Card)`
  width: 100%;
`;

/**
 * Renders a card for the spv documents tab
 *
 * @param {object} prop
 * @returns {JSX.Element}
 */
const DocumentsCard = () => {
  const [includeArchived, setIncludeArchived] = useState(false);
  const [formOpen, setFormOpen] = useState(false);

  const queryParams = { "filter[isArchived]": includeArchived ? undefined : false };

  const openForm = () => setFormOpen(true);
  const closeForm = () => setFormOpen(false);

  return (
    <StyledCard title="Documents" cardStyle={CardStyles.SECTIONED}>
      <StyledActions>
        {includeArchived ? (
          <ActionChip
            onClick={() => setIncludeArchived(prev => !prev)}
            leadingIcon={EyeIcon}
            text="Hide archived documents"
          />
        ) : (
          <ActionChip
            onClick={() => setIncludeArchived(prev => !prev)}
            leadingIcon={EyeOffIcon}
            text="Show archived documents"
          />
        )}
        <ActionChip onClick={openForm} leadingIcon={UploadIcon} text="Upload new SPV document" />
      </StyledActions>
      {formOpen && <SPVDocumentsForm closeModal={closeForm} />}
      <SPVDocumentsTable queryParams={queryParams} />
    </StyledCard>
  );
};

DocumentsCard.propTypes = {
  spv: PropTypes.shape({
    apiId: PropTypes.string.isRequired,
  }).isRequired,
};

export default DocumentsCard;

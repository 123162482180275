import PropTypes from "prop-types";
import { FinalFormField } from "yuka";

import { fetchQuery, QUERY_KEYS, useFetch } from "api";
import { useFormValue } from "utils/hooks/form";

const loadMoOptions = search =>
  fetchQuery(
    QUERY_KEYS.MARKET_OPERATORS.list({
      "filter[search]": search,
    })
  );

const MarketOperatorSelect = props => {
  const value = useFormValue(props.name);

  const { data: options } = useFetch(
    QUERY_KEYS.MARKET_OPERATORS.list({
      "filter[id]": value,
    }),
    {
      enabled: Boolean(value),
    }
  );

  return (
    <FinalFormField
      options={options}
      key={value && options ? "loaded" : "empty"}
      {...props}
      type="select"
      loadOptions={loadMoOptions}
      optionLabelKey="name"
      optionValueKey="apiId"
    />
  );
};

MarketOperatorSelect.propTypes = {
  name: PropTypes.string.isRequired,
};

export default MarketOperatorSelect;

import PropTypes from "prop-types";
import { Form } from "react-final-form";
import { FormModal, FinalFormField } from "yuka";

import { useWrite, fetchQuery, QUERY_KEYS, DataTypes } from "api";
import { queryClient } from "api/queryClient";
import LoadingSpinner from "utils/LoadingSpinner";
import { mpTrack } from "utils/mixpanel";

const loadSourceProfileOptions = search =>
  fetchQuery(QUERY_KEYS.CLIENT_PROFILES.list({ "filter[search]": search })).then(sourceProfiles =>
    sourceProfiles.map(sourceProfile => ({
      value: { apiId: sourceProfile.apiId, apiType: DataTypes.CLIENT_PROFILES },
      label: `${sourceProfile.name}`,
    }))
  );

const AddInvestmentForm = ({ spvClass, onClose }) => {
  const { onSubmit } = useWrite(
    QUERY_KEYS.SPV_CLASSES.detail(spvClass.apiId, ["bulk-add-investors"]),
    {
      method: "post",
    }
  );

  const wrappedOnSubmit = values =>
    onSubmit({
      ...values,
      apiType: DataTypes.SPV_INVESTMENT,
    }).then(() => {
      mpTrack("add spv investor", {
        spv: `${spvClass.masterName} ${spvClass.spvName} ${spvClass.name}`,
      });
      onClose();
      queryClient.invalidateQueries(QUERY_KEYS.SPV_INVESTMENTS.list());
    });

  return (
    <Form onSubmit={wrappedOnSubmit}>
      {({ submitting, handleSubmit }) => (
        <FormModal
          onClose={onClose}
          onSubmit={handleSubmit}
          submitText="Add to Class"
          title="Select clients to be added"
        >
          {submitting && <LoadingSpinner />}
          <FinalFormField
            type="select"
            name="source_profile"
            multiple
            loadOptions={loadSourceProfileOptions}
            label="Client name"
            placeholder="Select clients to add"
          />
        </FormModal>
      )}
    </Form>
  );
};

AddInvestmentForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  spvClass: PropTypes.shape({
    masterName: PropTypes.string,
    spvName: PropTypes.string,
    name: PropTypes.string,
    apiId: PropTypes.string,
  }).isRequired,
};

export default AddInvestmentForm;

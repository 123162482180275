import PropTypes from "prop-types";
import { useContext } from "react";
import { getRelatedObj } from "api";
import RelationshipsContext from "utils/RelationshipsContext";

const ZxRepCell = ({ value: zxRepIds }) => {
  const relationships = useContext(RelationshipsContext);

  if (zxRepIds) {
    const zxReps = zxRepIds.map(zxRepId => getRelatedObj(zxRepId, relationships));
    return zxReps.map(rep => rep.name).join(", ");
  }
  // Fallback for if zx rep is not defined.
  return "";
};

ZxRepCell.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string),
};

ZxRepCell.defaultProps = {
  value: [],
};

export default ZxRepCell;

import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { ColorPalette, Definition, ShieldCheckIcon, ShieldIcon, useTooltip } from "yuka";

import { ClientProfileLink } from "utils/routing";

const StyledSourceDefinitionContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;

  > :first-child {
    flex-grow: 1;
  }
`;

/**
 * Renders a single participant linked to relevant profiles as much as possible
 *
 * @param {object} props
 * @returns {React.Element}
 */
const SourceDefinition = ({ source, sourceRole }) => {
  const kycRef = React.useRef(null);
  const {
    isKycApproved,
    individualXeSourceId,
    individualName,
    entityXeSourceId,
    entityName,
    individual,
    entity,
  } = source;

  const individualContent = React.useMemo(
    () =>
      individualXeSourceId ? (
        <ClientProfileLink name={individualName} url={`/clients/${individualXeSourceId}`} />
      ) : (
        individualName
      ),
    [individualName, individualXeSourceId]
  );

  const entityContent = React.useMemo(
    () =>
      entityXeSourceId ? (
        <ClientProfileLink name={entityName} url={`/clients/${entityXeSourceId}`} />
      ) : (
        entityName
      ),
    [entityXeSourceId, entityName]
  );

  const participantElement = React.useMemo(() => {
    if (individual) {
      return entity ? (
        <>
          {individualContent}
          <span> @ </span>
          {entityContent}
        </>
      ) : (
        individualContent
      );
    }
    if (entity) {
      return entityContent;
    }
    return null;
  }, [entity, entityContent, individual, individualContent]);

  const kycTooltip = useTooltip(
    kycRef,
    isKycApproved ? "KYC Approved" : "KYC has not been approved"
  );

  return (
    <>
      {kycTooltip}
      <StyledSourceDefinitionContainer>
        <Definition label={sourceRole} value={participantElement} />
        <span ref={kycRef}>
          {isKycApproved ? (
            <ShieldCheckIcon color={ColorPalette.buy} />
          ) : (
            <ShieldIcon color={ColorPalette.white50} />
          )}
        </span>
      </StyledSourceDefinitionContainer>
    </>
  );
};

SourceDefinition.propTypes = {
  source: PropTypes.shape({
    individual: PropTypes.number,
    individualName: PropTypes.string,
    individualXeSourceId: PropTypes.string,
    isKycApproved: PropTypes.bool,
    entity: PropTypes.number,
    entityName: PropTypes.string,
    entityXeSourceId: PropTypes.string,
    ticketAccess: PropTypes.string.isRequired,
  }),
  sourceRole: PropTypes.string.isRequired,
};

export default SourceDefinition;

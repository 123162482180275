import { Form } from "react-final-form";
import { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { FormModal, Table } from "yuka";

import { useWrite, QUERY_KEYS, DataTypes } from "api";
import { queryClient } from "api/queryClient";
import LoadingSpinner from "utils/LoadingSpinner";
import { mpTrack } from "utils/mixpanel";
import { LinkDocumentsTable } from "templates/DocumentsTable";

import { headerTableColumns } from "./config";

const StyledContainer = styled.div`
  width: 1000px;
`;

const LinkParticipantDocsForm = ({
  connection,
  documents,
  onClose,
  name,
  participantType,
  source,
}) => {
  const { onSubmit } = useWrite(
    QUERY_KEYS.CONNECTIONS.detail(connection.tradeId, ["import_participant_documents"])
  );

  const headerTableData = useMemo(
    () => [
      {
        participantName: name,
        profileType: source.category,
        documentCount: documents.length,
      },
    ],
    [documents, name, source]
  );

  const [selectedDocuments, setSelectedDocuments] = useState([]);

  const wrappedOnSubmit = useCallback(
    (_, ...params) => {
      const documentsToLink = selectedDocuments.map(document => ({
        apiId: document.apiId,
        apiType: DataTypes.EXPIRABLE_DOCUMENT,
      }));
      const requestData = {
        apiId: connection.apiId,
        apiType: connection.apiType,
        tradeId: connection.tradeId,
        documents: documentsToLink,
      };
      onSubmit(requestData, ...params).then(() => {
        onClose();
        mpTrack("linked client documents to deal file", {
          "trade id": connection.tradeId,
          "participant name": name,
          "number of documents": documentsToLink.length,
        });
        queryClient.invalidateQueries(
          QUERY_KEYS.DEAL_FILE_BUYSIDE_DOCUMENTS.list(connection.tradeId)
        );
        queryClient.invalidateQueries(
          QUERY_KEYS.DEAL_FILE_SELLSIDE_DOCUMENTS.list(connection.tradeId)
        );
      });
    },
    [connection, name, onClose, onSubmit, selectedDocuments]
  );

  return (
    <Form onSubmit={wrappedOnSubmit}>
      {({ submitting, handleSubmit }) => (
        <FormModal
          onClose={onClose}
          onSubmit={handleSubmit}
          submitText="Confirm Import"
          title="Link Participant Documents"
          requireDirtyForSubmit={false}
        >
          {submitting && <LoadingSpinner />}
          <StyledContainer>
            <Table
              usePercentageColumnWidths
              data={headerTableData}
              columns={headerTableColumns(participantType)}
            />
            {documents.length > 0 && (
              <LinkDocumentsTable
                documents={documents}
                setSelectedDocuments={setSelectedDocuments}
                pk={source.apiId}
              />
            )}
          </StyledContainer>
        </FormModal>
      )}
    </Form>
  );
};

LinkParticipantDocsForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  connection: PropTypes.shape({
    apiId: PropTypes.string,
    apiType: PropTypes.string,
    tradeId: PropTypes.string,
  }).isRequired,
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      apiId: PropTypes.string.isRequired,
    })
  ),
  name: PropTypes.string.isRequired,
  participantType: PropTypes.string.isRequired,
  source: PropTypes.shape({
    apiId: PropTypes.string,
    category: PropTypes.string,
  }).isRequired,
};

export default LinkParticipantDocsForm;

import {
  KYC_APPROVED,
  KYC_EXPIRED,
  KYC_INCOMPLETE,
  KYC_REVIEW,
  KYC_INFORMATION_REQUESTED,
  KYC_INFORMATION_UPDATED,
} from "utils/constants";

const KYC_OPTIONS = [
  KYC_APPROVED,
  KYC_EXPIRED,
  KYC_INCOMPLETE,
  KYC_REVIEW,
  KYC_INFORMATION_REQUESTED,
  KYC_INFORMATION_UPDATED,
];

export { KYC_OPTIONS };

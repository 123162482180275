import {
  FEE_AGREEMENT_TYPE_STANDARD_FEE_SHARE,
  FEE_AGREEMENT_TYPE_RATE_CARD,
  FEE_AGREEMENT_TYPE_SUM_AND_SPLIT,
  FEE_AGREEMENT_DIVISION_OF_FEES_PERCENT_OF_TRANSACTION,
  FEE_AGREEMENT_DIVISION_OF_FEES_PERCENT_OF_FEES,
  FEE_AGREEMENT_DIVISION_OF_FEES_FRACTION_OF_FEES,
} from "utils/constants";
import { percentFormat } from "utils/displayFormatUtils";

/**
 * Display the formatted fee agreement amount
 *
 * @param {object} sourceProfile
 * @returns {string}
 */
const feeAgreementDisplay = sourceProfile => {
  if (!sourceProfile) {
    return null;
  }
  const feeAgreementAmount = Number(sourceProfile.feeAgreementAmount);
  switch (sourceProfile.feeAgreementType) {
    case FEE_AGREEMENT_TYPE_STANDARD_FEE_SHARE:
    case FEE_AGREEMENT_TYPE_RATE_CARD:
      switch (sourceProfile.feeAgreementDivisionOfFees) {
        case FEE_AGREEMENT_DIVISION_OF_FEES_PERCENT_OF_TRANSACTION:
          return percentFormat(feeAgreementAmount, 2);
        case FEE_AGREEMENT_DIVISION_OF_FEES_PERCENT_OF_FEES:
          return `${percentFormat(feeAgreementAmount, 2)} of fees`;
        case FEE_AGREEMENT_DIVISION_OF_FEES_FRACTION_OF_FEES:
          return `${sourceProfile.feeAgreementAmountNumerator} / ${sourceProfile.feeAgreementAmountDenominator} of fees`;
        default:
          // Should never reach default
          return null;
      }
    case FEE_AGREEMENT_TYPE_SUM_AND_SPLIT:
      return "Sum and Split";
    default:
      // Should never reach default
      return null;
  }
};

export { feeAgreementDisplay };

import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { ListItem, ListStyles, StyledCaption1 } from "yuka";

import { QUERY_KEYS, getRelatedObj, useFetch } from "api";
import AddClientForm from "forms/AddClientForm";
import {
  USER_TYPE_BD,
  USER_TYPE_CLIENT,
  getSourceTypeFromCategory,
} from "forms/AddClientForm/constants";

import ParticipantListItem from "./ParticipantListItem";

const StyledText = styled(StyledCaption1)`
  white-space: nowrap;
`;

/**
 * Helper component that gets the source profile of a source before displaying the source with
 * its associated documents
 */
const ParticipantListItemHelper = ({ connection, participantType, name, sourceId }) => {
  const [addClientModalOpen, setAddClientModalOpen] = useState(false);
  const queryParams = {
    include: "source",
    "filter[source_id]": sourceId,
  };
  const { isLoading, isError, data, relationships } = useFetch(
    QUERY_KEYS.CLIENT_PROFILES.list({ ...queryParams })
  );

  const { data: zxSourceData } = useFetch(
    QUERY_KEYS.SOURCES.list({ "filter[search]": name, "filter[has_no_source_profile]": true })
  );

  const zxSource = useMemo(() => zxSourceData?.[0], [zxSourceData]);
  const sourceType = useMemo(() => getSourceTypeFromCategory(zxSource?.category), [zxSource]);

  let sourceProfile = null;
  let source = null;
  if (data?.length) {
    sourceProfile = data[0];
    source = getRelatedObj(sourceProfile.source, relationships);
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>An error has occurred</div>;
  }

  const text = <StyledText>No EXE profile found</StyledText>;

  if (sourceProfile === null) {
    return (
      <>
        {addClientModalOpen && (
          <AddClientForm
            closeModal={() => setAddClientModalOpen(false)}
            initialSourceType={sourceType}
            userType={participantType.includes("Client") ? USER_TYPE_CLIENT : USER_TYPE_BD}
            zxSource={zxSource}
          />
        )}
        <ListItem
          listStyle={ListStyles.TWO_LINE}
          text={name}
          subtext={participantType}
          trailingContent={text}
          onClick={() => setAddClientModalOpen(true)}
        />
      </>
    );
  }

  if (source.apiId) {
    return (
      <ParticipantListItem
        connection={connection}
        name={name}
        participantType={participantType}
        source={source}
      />
    );
  }

  return null;
};

ParticipantListItemHelper.propTypes = {
  connection: PropTypes.shape({
    documents: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
    tradeId: PropTypes.string,
  }).isRequired,
  participantType: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  sourceId: PropTypes.number.isRequired,
};

export default ParticipantListItemHelper;

import PropTypes from "prop-types";
import styled from "styled-components";
import { useForm, useFormState } from "react-final-form";
import {
  Card,
  CardStyles,
  InputRow,
  FinalFormField,
  Button,
  DownloadIcon,
  RefreshIcon,
} from "yuka";

import { fetchQueryResponse, QUERY_KEYS } from "api";
import downloadFileResponse from "utils/downloadFileResponse";
import LoadingSpinner from "utils/LoadingSpinner";
import { Input } from "forms/FormComponents";
import { DEAL_FILE_FILTERS } from "forms/filterNames";
import Filter from "forms/Filter";

import { onChangeFilters } from "./config";

import { CATEGORY_OPTIONS } from "../constants";

const StyledWrapper = styled(InputRow)`
  margin-bottom: 0;
`;

const StyledLoadingSpinner = styled.div`
  margin: auto 0 auto auto;
`;

const DealFileFilterComponent = ({ connection, isLoading }) => {
  const { reset } = useForm();
  const { dirty } = useFormState();

  const downloadDealFile = () => {
    fetchQueryResponse(
      QUERY_KEYS.CONNECTIONS.detail(connection.tradeId, ["download_deal_file"])
    ).then(data => {
      downloadFileResponse(data, `${connection.tradeId} Deal File.zip`);
    });
  };

  return (
    <StyledWrapper>
      <Input name="name" placeholder="File name" />
      <FinalFormField
        type="checkbox-dropdown"
        name="category"
        placeholder="All tags"
        options={CATEGORY_OPTIONS.map(value => ({ label: value, value }))}
      />
      <Button disabled={!dirty} leadingIcon={RefreshIcon} onClick={() => reset()}>
        Reset Filters
      </Button>
      {isLoading && (
        <StyledLoadingSpinner>
          <LoadingSpinner showContainer={false} />
        </StyledLoadingSpinner>
      )}
      <Button leadingIcon={DownloadIcon} onClick={downloadDealFile}>
        Download Deal File
      </Button>
    </StyledWrapper>
  );
};

DealFileFilterComponent.propTypes = {
  connection: PropTypes.shape({
    tradeId: PropTypes.string.isRequired,
  }).isRequired,
  isLoading: PropTypes.bool,
};

DealFileFilterComponent.defaultProps = {
  isLoading: false,
};

const initialValues = { category: CATEGORY_OPTIONS };

const DealFileFilter = ({ connection }) => (
  <Card cardStyle={CardStyles.PADDED}>
    <Filter
      name={DEAL_FILE_FILTERS}
      filterComponent={DealFileFilterComponent}
      onChange={onChangeFilters}
      initialValues={initialValues}
      connection={connection}
    />
  </Card>
);

DealFileFilter.propTypes = {
  connection: PropTypes.shape({
    tradeId: PropTypes.string.isRequired,
  }).isRequired,
};

export default DealFileFilter;

import { INDIVIDUAL_SOURCES, ENTITY_SOURCES, TRUST_SOURCES, KYC_APPROVED } from "../constants";

const STATUS_ARCHIVED = "Archived";
const STATUS_DRAFT = "Draft";
const STATUS_LIVE = "Live";
const STATUS_PARTIALLY_EXECUTED = "Partially Executed";
const STATUS_FULLY_EXECUTED = "Fully Executed";

const STATUSES = {
  [STATUS_ARCHIVED]: "Archived",
  [STATUS_DRAFT]: "Draft",
  [STATUS_LIVE]: "Live",
  [STATUS_PARTIALLY_EXECUTED]: "Partially Executed",
  [STATUS_FULLY_EXECUTED]: "Fully Executed",
};

export {
  INDIVIDUAL_SOURCES,
  ENTITY_SOURCES,
  TRUST_SOURCES,
  KYC_APPROVED,
  STATUSES,
  STATUS_ARCHIVED,
};

import { debounce } from "lodash-es";
import { useMemo, useContext } from "react";

import { mpTrack } from "utils/mixpanel";
import { HALF_SECOND } from "utils/constants";
import { isEqual, findKey, pickBy } from "utils";
import { SPV_INVESTMENT_FILTERS } from "forms/filterNames";
import { GlobalContext } from "utils/StateProvider";

import { KYC_OPTIONS } from "./constants";

const fieldToEventTypeMap = {
  search: "Name / Trade ID",
  zxRepresentative: "Assigned ZX Rep",
  kycStatus: "KYC Status",
};

const onChangeFilters = debounce(
  (values, prevValues) => {
    const changedValue = findKey(pickBy(values, (val, key) => prevValues[key] !== val));
    if (changedValue || !isEqual(values, prevValues)) {
      mpTrack("filter spv investors table", {
        type: fieldToEventTypeMap[changedValue] || "Reset",
      });
    }
  },
  HALF_SECOND,
  { trailing: true }
);

const useInvestmentFilterConfig = () => {
  const {
    state: { forms },
  } = useContext(GlobalContext);

  const formValues = pickBy(forms[SPV_INVESTMENT_FILTERS] || {});
  const queryParams = useMemo(
    () => ({
      "filter[search]": formValues.search,
      "filter[zxRepresentative]": formValues.representative,
      "filter[sourceType]": formValues.sourceType,
      "filter[kycStatus]": formValues.kycStatus,
    }),
    [formValues]
  );

  return {
    queryParams,
    filterProps: {
      onChange: onChangeFilters,
      initialValues: {
        representative: formValues.representative,
        kycStatus: KYC_OPTIONS,
      },
    },
  };
};

export default useInvestmentFilterConfig;

import styled from "styled-components";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import { useDropdown, ColorPalette, StyledCaption2, StyledOverline, StyledBody1 } from "yuka";
import { useContext } from "react";

import { getRelatedObj } from "api";
import RelationshipsContext from "utils/RelationshipsContext";

import {
  STATUS_TYPE_STANDARD,
  STATUS_TYPE_IN_PROGRESS,
  STATUS_TYPE_BLOCKING,
  STATUS_TYPE_IN_PROGRESS_LABEL,
  STATUS_TYPE_BLOCKING_LABEL,
  STATUS_TYPE_STANDARD_LABEL,
} from "utils/constants";

const StyledStatusDot = styled.div`
  background: ${props => props.$background};
  border-radius: 50%;
  flex-shrink: 0;
  height: 8px;
  width: 8px;
`;

const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  gap: 4px;
  padding: 0 16px;
`;

const StyledFlex = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const StatusCell = ({ value: connection }) => {
  const relationships = useContext(RelationshipsContext);
  let statusUpdateObj = null;
  // Status updates are received as an array, so get the last one.
  let [statusDot, statusType] = [<></>, ""];
  const statusUpdateObjs = connection.statusUpdate
    .map(update => getRelatedObj(update, relationships))
    .filter(update => update.isPinned);

  if (statusUpdateObjs.length > 0) {
    statusUpdateObj = statusUpdateObjs[statusUpdateObjs.length - 1];
    switch (statusUpdateObj.statusType) {
      case STATUS_TYPE_STANDARD:
        [statusDot, statusType] = [
          <StyledStatusDot key="statusDot" $background={ColorPalette.blue500} />,
          STATUS_TYPE_STANDARD_LABEL,
        ];
        break;
      case STATUS_TYPE_IN_PROGRESS:
        [statusDot, statusType] = [
          <StyledStatusDot key="statusDot" $background={ColorPalette.yellowLight} />,
          STATUS_TYPE_IN_PROGRESS_LABEL,
        ];
        break;
      case STATUS_TYPE_BLOCKING:
        [statusDot, statusType] = [
          <StyledStatusDot key="statusDot" $background={ColorPalette.redDark} />,
          STATUS_TYPE_BLOCKING_LABEL,
        ];
        break;
      default:
        // Should never happen in practice
        [statusDot, statusType] = [
          <StyledStatusDot key="statusDot" $background={ColorPalette.blue500} />,
          STATUS_TYPE_STANDARD_LABEL,
        ];
        break;
    }
  }

  const renderContents = () => (
    <StyledCard>
      <StyledFlex>
        {statusDot}
        <StyledOverline>{statusType}</StyledOverline>
      </StyledFlex>
      <StyledBody1>{statusUpdateObj?.content ?? ""}</StyledBody1>
      <StyledCaption2>
        {statusUpdateObj?.authorFullName ?? ""} |{" "}
        {new Date(statusUpdateObj?.createdOn).toLocaleDateString("en-us", {
          month: "short",
          day: "numeric",
          year: "numeric",
        })}
      </StyledCaption2>
    </StyledCard>
  );

  const [dropdown, ref, toggleDisplay] = useDropdown(renderContents, {});

  return (
    <div ref={ref} onMouseEnter={toggleDisplay} onMouseLeave={toggleDisplay}>
      {statusDot}
      {ReactDOM.createPortal(dropdown, document.body)}
    </div>
  );
};

StatusCell.propTypes = {
  value: PropTypes.shape({
    statusUpdate: PropTypes.array,
  }),
};

export default StatusCell;

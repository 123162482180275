import { useContext } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { getRelatedObj } from "api";
import { FontColors, overline } from "yuka";
import { SPV, INTERNAL_SPV, FORWARD } from "utils/constants";
import RelationshipsContext from "utils/RelationshipsContext";

const StyledSpan = styled.span`
  ${FontColors.theme80};
`;

const StyledSubscript = styled.span`
  ${overline};
  ${FontColors.theme50};
  margin-left: 4px;
`;

const CompanyCell = ({ value: connection }) => {
  const relationships = useContext(RelationshipsContext);
  const companyObj = getRelatedObj(connection.company, relationships);

  return (
    <div>
      <StyledSpan>{companyObj.name}</StyledSpan>
      {connection.tradeType === SPV && <StyledSubscript>spv</StyledSubscript>}
      {connection.tradeType === INTERNAL_SPV && <StyledSubscript>Z-spv</StyledSubscript>}
      {connection.tradeType === FORWARD && <StyledSubscript>Forward</StyledSubscript>}
    </div>
  );
};

CompanyCell.propTypes = {
  value: PropTypes.shape({
    company: PropTypes.number.isRequired,
    tradeType: PropTypes.string,
  }).isRequired,
};

export default CompanyCell;

import styled from "styled-components";
import { useContext, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Card, CardStyles, ChevronDownIcon, ChevronRightIcon } from "yuka";

import { getRelatedObj } from "api";
import RelationshipsContext from "utils/RelationshipsContext";
import { compact } from "utils";
import { ConnectionDocumentsTable } from "templates/DocumentsTable";

import { useDealFileFilterConfig } from "./DealFileFilter";
import DealFileFolderActions from "./DealFileFolderActions";

const StyledCard = styled(Card)`
  width: 100%;
`;

const StyledTitle = styled.div`
  cursor: pointer;
`;
const StyledTitleSpan = styled.span`
  margin-left: 12px;
`;

/**
 * Table displaying all documents in the Deal File
 */
const DealFileTable = ({ connection, documents, tableName, title, queryKeys }) => {
  const [showTable, setShowTable] = useState(true);
  const { queryParams } = useDealFileFilterConfig();

  const relationships = useContext(RelationshipsContext);
  const documentObjects = useMemo(
    () => compact(documents.map(document => getRelatedObj(document, relationships))),
    [documents, relationships]
  );

  const [selectedDocuments, setSelectedDocuments] = useState([]);

  const cardHeader = (
    <StyledTitle role="button" onClick={() => setShowTable(!showTable)}>
      {showTable ? <ChevronDownIcon /> : <ChevronRightIcon />}
      <StyledTitleSpan>{title}</StyledTitleSpan>
    </StyledTitle>
  );

  const headerTrailingContent = (
    <DealFileFolderActions
      selectedDocuments={selectedDocuments}
      connection={connection}
      queryKeys={queryKeys}
      title={title}
    />
  );

  return (
    <StyledCard
      cardStyle={CardStyles.SECTIONED}
      title={cardHeader}
      headerTrailingContent={headerTrailingContent}
    >
      {showTable && (
        <>
          <ConnectionDocumentsTable
            connection={connection}
            setSelectedDocuments={setSelectedDocuments}
            queryKeys={queryKeys}
            documents={documentObjects}
            tableName={tableName}
            queryParams={queryParams}
          />
        </>
      )}
    </StyledCard>
  );
};

DealFileTable.propTypes = {
  connection: PropTypes.shape({
    tradeId: PropTypes.string.isRequired,
  }).isRequired,
  documents: PropTypes.arrayOf(PropTypes.array).isRequired,
  tableName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  queryKeys: PropTypes.shape({
    detail: PropTypes.func,
    list: PropTypes.func,
  }).isRequired,
};

export default DealFileTable;

import React from "react";

import { StyledHeadline2 } from "yuka";

import { Checkbox } from "forms/FormComponents";
import { useFormValue } from "utils/hooks/form";

import ClientAgreementDetails from "./ClientAgreementDetails";
import ClientFirmOrderDetails from "./ClientFirmOrderDetails";
import {
  BUYER_AGREEMENT,
  SELLER_AGREEMENT,
  BUYER_FIRM_ORDER,
  SELLER_FIRM_ORDER,
} from "./constants";

const DocumentDetails = () => {
  const engagementType = useFormValue("engagementType");
  const needsKYC = useFormValue("needsKyc", false);
  return (
    <>
      {engagementType === BUYER_AGREEMENT && <ClientAgreementDetails isBuy />}
      {engagementType === SELLER_AGREEMENT && <ClientAgreementDetails isBuy={false} />}
      {engagementType === BUYER_FIRM_ORDER && <ClientFirmOrderDetails isBuy />}
      {engagementType === SELLER_FIRM_ORDER && <ClientFirmOrderDetails isBuy={false} />}
      {needsKYC && (
        <>
          <StyledHeadline2>KYC Details</StyledHeadline2>
          {engagementType !== SELLER_AGREEMENT && engagementType !== SELLER_FIRM_ORDER && (
            <Checkbox name="sendBuysideQuestionnaire" label="Send buyside investor questionnaire" />
          )}
          {engagementType !== BUYER_AGREEMENT && engagementType !== BUYER_FIRM_ORDER && (
            <>
              <Checkbox
                name="sendSellsideQuestionnaire"
                label="Send sellside investor questionnaire"
              />
              <Checkbox name="sendWireInstructions" label="Send wire instructions onboarding" />
            </>
          )}
        </>
      )}
    </>
  );
};

export default DocumentDetails;

import PropTypes from "prop-types";
import styled from "styled-components";

import { PageSection, StyledBody1, Button, LinkTypes } from "yuka";

import ZXLogo from "utils/ZXLogo";

const Layout = styled(PageSection)`
  flex-direction: row;
  height: 100vh;
  width: 100%;
`;

const StyledErrorMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  gap: 8px;
`;

const StyledClientOnboardingCompleted = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 0px auto;
  margin-top: 16px;
  align-items: center;
`;

const ErrorPage = ({ text, linkHome, customButtonOnClick, customButtonText }) => (
  <StyledErrorMessage>
    <ZXLogo size={96} />
    <StyledBody1>{text}</StyledBody1>
    {linkHome && (
      <Button url="/" linkType={LinkTypes.LOCAL_LINK}>
        Go Home
      </Button>
    )}
    {customButtonOnClick && (
      <Button onClick={() => customButtonOnClick()}>{customButtonText}</Button>
    )}
  </StyledErrorMessage>
);

ErrorPage.propTypes = {
  text: PropTypes.string.isRequired,
  linkHome: PropTypes.bool,
  customButtonOnClick: PropTypes.func,
  customButtonText: PropTypes.string,
};

ErrorPage.defaultProps = {
  linkHome: false,
};

const Unauthorized = () => <ErrorPage text="You do not have permission to view this site" />;
const NotFound = () => (
  <ErrorPage text="The page you are searching for could not be found" linkHome />
);
const FrontendError = () => (
  <ErrorPage text="Something went wrong. Please refresh the page and try again. Contact an administrator if this keeps happening" />
);
const Loading = () => <ErrorPage text="Loading... Please wait" />;
const NotFoundNoHomePage = () => (
  <Layout>
    <ErrorPage text="Unable to find the page you are trying to reach. Please check if you are using an outdated link" />
  </Layout>
);
const InvalidTokenPage = () => (
  <ErrorPage text="This link has expired. Please contact execution@zanbato.com to request a new link" />
);
const NewOnboardingRequestSent = () => (
  <ErrorPage text="A new onboarding request link has been sent. Check your inbox for the most up to date link" />
);
const ExpiredRequestPage = ({ resendOnboardingRequest }) => (
  <ErrorPage
    text="This link has expired. You can request and new link and it will be sent to your email"
    customButtonOnClick={resendOnboardingRequest}
    customButtonText="Request new link"
  />
);
const ClientOnboardingCompletedPage = () => (
  <Layout>
    <ErrorPage
      text={
        <StyledClientOnboardingCompleted>
          <span>
            That's it! Thank you for completing all of the necessary steps at this time. Our team
            will follow up with any final questions and you will then receive your completed
            documents via DocuSign.
          </span>
          <span>
            Please don't hesitate to contact us at execution@zanbato.com if you have any questions
            or concerns.
          </span>
        </StyledClientOnboardingCompleted>
      }
    />
  </Layout>
);
const IraInvestorPage = () => (
  <Layout>
    <ErrorPage text="Investing through an IRA, Employee Benefit Plan, or an ERISA member is a unique investing case. Please contact us at execution@zanbato.com to continue the onboarding" />
  </Layout>
);

ExpiredRequestPage.propTypes = {
  resendOnboardingRequest: PropTypes.func.isRequired,
};

export {
  Unauthorized,
  NotFound,
  NotFoundNoHomePage,
  FrontendError,
  Loading,
  InvalidTokenPage,
  NewOnboardingRequestSent,
  ExpiredRequestPage,
  ClientOnboardingCompletedPage,
  IraInvestorPage,
};

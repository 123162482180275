import { useMemo, useContext, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { BadgeCell } from "yuka";

import { getRelatedId } from "api";
import { useMpSource, mpTrack } from "utils/mixpanel";
import { GlobalContext, ACTIONS } from "utils/StateProvider";
import useTableSort from "utils/tables/useTableSort";
import { KYC_BADGE_MAPPING } from "utils/constants";

import ZxRepCell from "./ZxRepCell";

const TABLE_NAME = "CLIENT_PROFILES";
const INITIAL_SORT_STATE = { id: "name", desc: false };

const useClientTableConfig = (basepath = "") => {
  const {
    state: { tableSort },
    dispatch,
  } = useContext(GlobalContext);
  const navigate = useNavigate();
  const mpSource = useMpSource();

  const onRowClick = useCallback(
    ({ row: { original: originalData } }) => {
      mpTrack("click into client profile", {
        mpSource,
        client: originalData.name,
        type: originalData.sourceType,
        "kyc status": originalData.kycStatus,
      });
      const sourceId = getRelatedId(originalData.source);
      if (sourceId) {
        navigate(`${basepath}${sourceId}/`);
      }
    },
    [navigate, mpSource, basepath]
  );

  const onSort = useTableSort(ACTIONS, dispatch, TABLE_NAME);
  const sortState = useMemo(() => {
    return tableSort[TABLE_NAME] || [{ ...INITIAL_SORT_STATE, hideArrow: true }];
  }, [tableSort]);

  const sortQueryParam = useMemo(() => {
    if (sortState?.length) {
      return sortState[0].desc ? `-${sortState[0].id}` : sortState[0].id;
    }
    return undefined;
  }, [sortState]);

  return {
    onRowClick,
    sortState,
    sortQueryParam,
    onSort,
  };
};

const NameColumn = {
  id: "name",
  accessor: "name",
  width: 235,
  header: "Name",
  sortable: true,
};

const SourceTypeColumn = {
  id: "sourceType",
  accessor: "sourceType",
  width: 235,
  header: "Type",
  sortable: true,
};

const KycStatusColumn = {
  id: "kycStatus",
  accessor: "kycStatus",
  width: 235,
  cellRenderer: BadgeCell,
  badgeMapping: KYC_BADGE_MAPPING,
  header: "KYC Status",
  sortable: true,
};

const ZxRepColumn = {
  id: "representative",
  accessor: sourceProfile => sourceProfile.zxRepresentatives,
  width: 235,
  header: "Assigned ZX Rep",
  cellRenderer: ZxRepCell,
};

const columns = [NameColumn, SourceTypeColumn, KycStatusColumn, ZxRepColumn];

export { columns, useClientTableConfig };

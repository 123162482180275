import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Form } from "react-final-form";
import styled from "styled-components";

import { FormModal } from "yuka";

import { getRelatedObj, useWrite, QUERY_KEYS } from "api";

import { TextArea } from "forms/FormComponents";
import { getReviewRequestedStatus } from "utils/connections";
import LoadingSpinner from "utils/LoadingSpinner";
import RelationshipsContext from "utils/RelationshipsContext";

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

/**
 * Form to request compliance review of a deal file.
 *
 * @returns {Element}
 */
const RequestComplianceReviewForm = ({ connection, onClose }) => {
  const { onSubmit } = useWrite(
    QUERY_KEYS.CONNECTIONS.detail(connection.tradeId, ["request_deal_file_review"]),
    { message: "Deal file sent for compliance review." }
  );
  const relationships = useContext(RelationshipsContext);
  const sourcesToKyc = connection.sourcesToKyc.map(source => getRelatedObj(source, relationships));

  const wrappedOnSubmit = (values, ...params) => {
    const submitValues = {
      ...values,
      dealFileStatus: getReviewRequestedStatus(connection),
      tradeId: connection.tradeId,
      apiType: connection.apiType,
    };
    delete submitValues.selectAll;
    onSubmit(submitValues, ...params).then(() => {
      onClose();
    });
  };

  const initialValues = {
    sourcesToKyc: sourcesToKyc,
    dealFileNotes: connection.dealFileNotes,
  };

  return (
    <Form onSubmit={wrappedOnSubmit} initialValues={initialValues}>
      {({ submitting, handleSubmit }) => (
        <FormModal
          onClose={onClose}
          onSubmit={handleSubmit}
          submitText="Submit"
          title="Send for Compliance Review"
        >
          <StyledWrapper>
            <TextArea
              name="dealFileNotes"
              placeholder="Provide additional context that may help Compliance with reviewing this deal file (Ex. trade direct with client or between brokers or any other nuance)"
              title="Additional Context"
            />
            {submitting && <LoadingSpinner />}
          </StyledWrapper>
        </FormModal>
      )}
    </Form>
  );
};

RequestComplianceReviewForm.propTypes = {
  connection: PropTypes.shape({
    tradeId: PropTypes.string,
    apiType: PropTypes.string,
    buy: PropTypes.arrayOf(PropTypes.string),
    sell: PropTypes.arrayOf(PropTypes.string),
    sourcesToKyc: PropTypes.arrayOf(PropTypes.array).isRequired,
    dealFileNotes: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default RequestComplianceReviewForm;

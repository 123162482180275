import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { FinalFormField, StyledHeadline2 } from "yuka";

import { DelimitedNumberInput, Input, InputRow, RadioGroup, Select } from "forms/FormComponents";
import { isNotNegative, isValidDecimal, required } from "utils/form/inputValidations";
import { useFormValue } from "utils/hooks/form";

import { commissionOptions, isSolicitedOptions, yesNoOptions } from "./constants";
import { loadCompanyOptions } from "./loadCompanyOptions";

const StyledContainer = styled.div`
  width: 560px;
`;

const StyledHeadline = styled(StyledHeadline2)`
  margin-bottom: 24px;
`;

const StyledInputRow = styled(InputRow)`
  align-items: end;
`;

const ClientAgreementDetails = ({ isBuy }) => {
  const isCompanyAffiliate = useFormValue("isCompanyAffiliate");
  const isCompanyInsider = useFormValue("isCompanyInsider");
  return (
    <StyledContainer>
      <StyledHeadline>{isBuy ? "Buyer" : "Seller"} Agreement Details</StyledHeadline>
      <InputRow>
        <FinalFormField
          type="select"
          loadOptions={loadCompanyOptions}
          name="company"
          placeholder="Issuing Company"
          label="Company"
          validate={[required]}
        />
      </InputRow>
      <StyledInputRow>
        <DelimitedNumberInput
          title="Commission"
          name="commissionAmount"
          placeholder="E.g. 10"
          validate={[isNotNegative, isValidDecimal, required]}
        />
        <Select
          title="Commission Method"
          name="commissionType"
          options={commissionOptions}
          placeholder="E.g. Price per share, % of total"
          noSort
          validate={[required]}
        />
      </StyledInputRow>
      <InputRow>
        <RadioGroup
          label="How was this order initiated? (optional)"
          items={isSolicitedOptions}
          name="isSolicited"
        />
      </InputRow>
      <InputRow>
        <RadioGroup
          label={`${isBuy ? "Buyer" : "Seller"} is an affiliate of the company? (optional)`}
          items={yesNoOptions}
          name="isCompanyAffiliate"
        />
      </InputRow>
      <InputRow>
        <RadioGroup
          label={`${isBuy ? "Buyer" : "Seller"} is a company insider? (optional)`}
          items={yesNoOptions}
          name="isCompanyInsider"
        />
      </InputRow>
      {(isCompanyAffiliate || isCompanyInsider) && (
        <InputRow>
          <Input
            name="currentPosition"
            title="Current Position (optional)"
            placeholder="e.g. Founder CEO"
          />
        </InputRow>
      )}
    </StyledContainer>
  );
};

ClientAgreementDetails.propTypes = {
  isBuy: PropTypes.bool.isRequired,
};

export default ClientAgreementDetails;
